import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useRef } from 'react';
import { useIntl } from 'react-intl';

import { useTrackEventAmplitude } from '../../../../../core/Tracking/Amplitude/Amplitude';
import { IconChevronDown } from '../../../../../design-system/components/icons/ChevronDown';
import { IconHeartFilled } from '../../../../../design-system/components/icons/HeartFilled';
import { IconLuggage } from '../../../../../design-system/components/icons/Luggage';
import { IconSettings } from '../../../../../design-system/components/icons/Settings';
import { IconSignOut } from '../../../../../design-system/components/icons/SignOut';
import { IconStars } from '../../../../../design-system/components/icons/Stars';
import { MenuItem } from '../../../../../design-system/components/nav/menu-item/MenuItem';
import { NavBarItem } from '../../../../../design-system/components/nav/nav-bar-item/NavBarItem';
import { Typography } from '../../../../../design-system/components/typography/Typography';
import { User } from '../../../../../resources/user/domain/types/User';
import { useUrlGenerator } from '../../../../../shared/hooks/useUrlGenerator';

import styles from './UserAccountDropdown.module.css';

interface Props {
    user: User;
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}

export function UserAccountDropdown({
    user,
    isOpen,
    onOpenChange,
    onMouseEnter,
    onMouseLeave,
}: Props) {
    const { formatMessage } = useIntl();
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();
    const triggerRef = useRef<HTMLButtonElement | null>(null);

    return (
        <DropdownMenu.Root modal={false} open={isOpen}>
            <DropdownMenu.Trigger
                asChild
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onClick={() => {
                    onOpenChange(!isOpen);

                    trackEvent((ampli, defaultProperties) => {
                        ampli.primaryNavAccountClicked({
                            ...defaultProperties,
                            block: 'primary_nav',
                        });
                    });
                }}
            >
                <NavBarItem
                    as="button"
                    ref={triggerRef}
                    className="amp-mask"
                    displayDesktopIconLeading={true}
                    iconLeading={<ProfileLetter name={user.firstName || 'Evaneos'} />}
                    iconTrailing={<IconChevronDown />}
                    isMenuOpen={isOpen}
                >
                    {user.firstName ||
                        formatMessage({
                            id: 'global.header.globalNav.account.label',
                        })}
                </NavBarItem>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content
                    side="bottom"
                    align="end"
                    sideOffset={18}
                    className={styles.menu}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onPointerDownOutside={(event) => {
                        // Avoid closing the dropdown when clicking on the dropdown trigger button.
                        let { target } = event;
                        while (target && target !== document.body) {
                            if (!(target instanceof HTMLElement)) {
                                return;
                            }
                            if (target.id === triggerRef.current?.id) {
                                return;
                            }
                            target = target.parentElement;
                        }

                        // Because this menu is not like the others on small screens,
                        // it is not full width & full height so user can click outside to close.
                        onOpenChange(false);
                    }}
                >
                    <DropdownMenu.Item asChild>
                        <MenuItem
                            className={styles.menuListItem}
                            href={generatePath('account_trips')}
                            Icon={IconLuggage}
                            onClick={() => {
                                trackEvent((ampli, defaultProperties) => {
                                    ampli.primaryNavTripsListClicked({
                                        ...defaultProperties,
                                        block: 'primary_nav',
                                    });
                                });
                            }}
                        >
                            {formatMessage({
                                id: 'global.header.globalNav.account.trips',
                            })}
                        </MenuItem>
                    </DropdownMenu.Item>
                    {user.recommendationsIsEnabled ? (
                        <DropdownMenu.Item asChild>
                            <MenuItem
                                className={styles.menuListItem}
                                href={generatePath('account_recommendations', {
                                    source: 'navigation_bar',
                                })}
                                Icon={IconStars}
                                onClick={() => {
                                    trackEvent((ampli, defaultProperties) => {
                                        ampli.primaryNavRecommendationClicked({
                                            ...defaultProperties,
                                            block: 'primary_nav',
                                        });
                                    });
                                }}
                            >
                                {formatMessage({
                                    id: 'global.header.globalNav.recommendations.label',
                                })}
                            </MenuItem>
                        </DropdownMenu.Item>
                    ) : null}
                    <DropdownMenu.Item asChild>
                        <MenuItem
                            className={styles.menuListItem}
                            href={generatePath('account_wishlist')}
                            Icon={IconHeartFilled}
                            onClick={() => {
                                trackEvent((ampli, defaultProperties) => {
                                    ampli.primaryNavFavoriteClicked({
                                        ...defaultProperties,
                                        block: 'primary_nav',
                                    });
                                });
                            }}
                        >
                            {formatMessage({
                                id: 'global.header.globalNav.account.wishes',
                            })}
                        </MenuItem>
                    </DropdownMenu.Item>
                    <DropdownMenu.Item asChild>
                        <MenuItem
                            className={styles.menuListItem}
                            href={generatePath('account_settings')}
                            Icon={IconSettings}
                            onClick={() => {
                                trackEvent((ampli, defaultProperties) => {
                                    ampli.primaryNavPersonalInfoClicked({
                                        ...defaultProperties,
                                        block: 'primary_nav',
                                    });
                                });
                            }}
                        >
                            {formatMessage({
                                id: 'global.header.globalNav.account.settings',
                            })}
                        </MenuItem>
                    </DropdownMenu.Item>
                    <DropdownMenu.Separator asChild>
                        <hr className={styles.hr} />
                    </DropdownMenu.Separator>
                    <DropdownMenu.Item asChild>
                        <MenuItem
                            className={styles.menuListItem}
                            href={generatePath('logout')}
                            Icon={IconSignOut}
                            onClick={() => {
                                trackEvent((ampli, defaultProperties) => {
                                    ampli.primaryNavLogoutClicked({
                                        ...defaultProperties,
                                        block: 'primary_nav',
                                    });
                                });
                            }}
                        >
                            {formatMessage({
                                id: 'global.header.globalNav.account.logout',
                            })}
                        </MenuItem>
                    </DropdownMenu.Item>
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
}

function ProfileLetter({ name }: { name: string }) {
    return (
        <Typography data-testid="Badge" className={styles.profileLetter} scale="primary-xxs-bold">
            {name[0]}
        </Typography>
    );
}
