import { HTMLAttributes, RefObject } from 'react';
import { useIntl } from 'react-intl';

import { useTrackEventAmplitude } from '../../../../../core/Tracking/Amplitude/Amplitude';
import { Button } from '../../../../../design-system/components/form/button/Button';
import { IconForest } from '../../../../../design-system/components/icons/Forest';
import { IconGroups } from '../../../../../design-system/components/icons/Groups';
import { IconPartlyCloudDayRoundedFilled } from '../../../../../design-system/components/icons/PartlyCloudyDayRoundedFilled';
import { IconTimelapseRounded } from '../../../../../design-system/components/icons/TimelapseRounded';
import {
    NavMenuVerticalTab,
    TabContent,
    TabList,
    TabRoot,
} from '../../../../../design-system/components/tabs/TabList';
import { useUrlGenerator } from '../../../../../shared/hooks/useUrlGenerator';
import { PrimaryNavWhereToGoMenu } from '../../types';
import { DropdownContent } from '../DropdownContent/DropdownContents';

import { HowLongTabContent } from './tabs/HowLongTabContent';
import { WhatTabContent } from './tabs/WhatTabContent';
import { WhenTabContent } from './tabs/WhenTabContent';
import { WhoTabContent } from './tabs/WhoTabContent';
import style from './WhereToGoDropdownContent.module.css';

type Props = HTMLAttributes<HTMLDivElement> & {
    onClickOutside: () => void;
    triggerRef: RefObject<HTMLElement | null>;
    whereToGoMenu: NonNullable<PrimaryNavWhereToGoMenu>;
};

export function WhereToGoDropdownContent({ whereToGoMenu, ...props }: Props) {
    const { generatePath } = useUrlGenerator();
    const { formatMessage } = useIntl();
    const { who, what, when, howLong } = whereToGoMenu;
    const { trackEvent } = useTrackEventAmplitude();

    const tabsValues = ['who', 'what', 'when', 'howLong'] as const;
    const defaultValue = tabsValues.find((tab) => {
        return whereToGoMenu[tab];
    });

    return (
        <DropdownContent {...props} backgroundColor="dark">
            <TabRoot orientation="vertical" defaultValue={defaultValue} className={style.container}>
                <div className={style.tabsContainer}>
                    <TabList direction="vertical">
                        {who ? (
                            <NavMenuVerticalTab
                                value="who"
                                Icon={IconGroups}
                                onClick={() => {
                                    trackEvent((ampli, defaultProperties) => {
                                        ampli.primaryNavThematicSelected({
                                            ...defaultProperties,
                                            block: 'primary_nav',
                                            row_number: 1,
                                            thematic_group: 'who',
                                        });
                                    });
                                }}
                            >
                                {who.title}
                            </NavMenuVerticalTab>
                        ) : null}

                        {when ? (
                            <NavMenuVerticalTab
                                value="when"
                                Icon={IconPartlyCloudDayRoundedFilled}
                                onClick={() => {
                                    trackEvent((ampli, defaultProperties) => {
                                        ampli.primaryNavThematicSelected({
                                            ...defaultProperties,
                                            block: 'primary_nav',
                                            row_number: 2,
                                            thematic_group: 'when',
                                        });
                                    });
                                }}
                            >
                                {when.title}
                            </NavMenuVerticalTab>
                        ) : null}

                        {what ? (
                            <NavMenuVerticalTab
                                value="what"
                                Icon={IconForest}
                                onClick={() => {
                                    trackEvent((ampli, defaultProperties) => {
                                        ampli.primaryNavThematicSelected({
                                            ...defaultProperties,
                                            block: 'primary_nav',
                                            row_number: 3,
                                            thematic_group: 'what',
                                        });
                                    });
                                }}
                            >
                                {what.title}
                            </NavMenuVerticalTab>
                        ) : null}

                        {howLong ? (
                            <NavMenuVerticalTab
                                value="how_long"
                                Icon={IconTimelapseRounded}
                                onClick={() => {
                                    trackEvent((ampli, defaultProperties) => {
                                        ampli.primaryNavThematicSelected({
                                            ...defaultProperties,
                                            block: 'primary_nav',
                                            row_number: 4,
                                            thematic_group: 'how_long',
                                        });
                                    });
                                }}
                            >
                                {howLong.title}
                            </NavMenuVerticalTab>
                        ) : null}
                    </TabList>

                    <Button
                        priority="secondary"
                        reversed={true}
                        isFullWidth={true}
                        href={generatePath('where_to_go')}
                        onClick={() => {
                            trackEvent((ampli, defaultProperties) => {
                                ampli.primaryNavWheretogoClicked({
                                    ...defaultProperties,
                                    block: 'primary_nav',
                                });
                            });
                        }}
                    >
                        {formatMessage({ id: 'global.header.globalNav.new.whereToGo.cta' })}
                    </Button>
                </div>

                <div className={style.tabsContentsContainer}>
                    {who ? (
                        <TabContent value="who" forceMount={true} className={style.tabContent}>
                            <WhoTabContent who={who} />
                        </TabContent>
                    ) : null}

                    {when ? (
                        <TabContent value="when" forceMount={true} className={style.tabContent}>
                            <WhenTabContent when={when} />
                        </TabContent>
                    ) : null}

                    {what ? (
                        <TabContent value="what" forceMount={true} className={style.tabContent}>
                            <WhatTabContent what={what} />
                        </TabContent>
                    ) : null}

                    {howLong ? (
                        <TabContent value="how_long" forceMount={true} className={style.tabContent}>
                            <HowLongTabContent howLong={howLong} />
                        </TabContent>
                    ) : null}
                </div>
            </TabRoot>
        </DropdownContent>
    );
}
