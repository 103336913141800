import cx from 'classnames';
import { ComponentProps } from 'react';

import { IconArrowRight } from '../../icons/ArrowRight';
import { Typography } from '../../typography/Typography';

import style from './NavMenuArrowButton.module.css';

type NavMenuArrowButtonProps = ComponentProps<'a'>;

export function NavMenuArrowButton({ className, children, ...props }: NavMenuArrowButtonProps) {
    return (
        <Typography
            {...props}
            as="a"
            className={cx(className, style.navMenuArrowButton)}
            scale="primary-xs-bold"
        >
            {children}
            <div className={style.iconCircle}>
                <IconArrowRight className={style.icon} />
            </div>
        </Typography>
    );
}
