import { useTrackEventAmplitude } from '../../../../../../core/Tracking/Amplitude/Amplitude';
import { NavMenuImageCard } from '../../../../../../design-system/components/nav/nav-menu-image-card/NavMenuImageCard';
import { NavMenuLink } from '../../../../../../design-system/components/nav/nav-menu-link/NavMenuLink';
import { Typography } from '../../../../../../design-system/components/typography/Typography';
import { WhereToGoMenu } from '../../../../../../resources/whereToGoMenu/domain/types/whereToGoMenu';
import { useUrlGenerator } from '../../../../../../shared/hooks/useUrlGenerator';

import style from './WhatTabContent.module.css';

type WhatTabContentProps = {
    what: WhereToGoMenu['what'];
};

export function WhatTabContent({ what }: WhatTabContentProps) {
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();

    return (
        <div className={style.tabContent}>
            {what.thematicsHighlight ? (
                <div className={style.highlightsSection}>
                    <Typography className={style.title} scale="primary-xs-bold">
                        {what.thematicsHighlight.title}
                    </Typography>

                    <div className={style.menuImagesWrapper}>
                        {what.thematicsHighlight.thematics.slice(0, 5).map((thematic, index) => (
                            <NavMenuImageCard
                                key={thematic.hubpage.id}
                                label={thematic.label}
                                blur={true}
                                obfuscate={true}
                                href={generatePath('hub_thematic', {
                                    thematicSlug: thematic.hubpage.slug,
                                })}
                                image={{
                                    alt: thematic.hubpage.coverPicture.card_cover_mobile.alt,
                                    src: thematic.hubpage.coverPicture.card_cover_mobile.url,
                                    loading: 'lazy',
                                    unoptimized: true,
                                }}
                                onClick={() => {
                                    trackEvent((ampli, defaultProperties) => {
                                        ampli.primaryNavThematicClicked({
                                            ...defaultProperties,
                                            block: 'primary_nav',
                                            card_number: index + 1,
                                            row_number: 3,
                                            thematic_slug: thematic.hubpage.slug,
                                            click_type: 'card',
                                            column_number: 0,
                                            thematic_group: 'what',
                                        });
                                    });
                                }}
                            />
                        ))}
                    </div>
                </div>
            ) : null}

            <div className={style.columnsSection}>
                {what.thematicsColumns.map((column, index) => {
                    return (
                        <div className={style.column} key={index + 1}>
                            <Typography className={style.title} scale="primary-xs-bold">
                                {column.title || ' '}
                            </Typography>

                            <div className={style.columnItemsWrapper}>
                                {column.thematics.slice(0, 7).map((thematic, itemsIndex) => (
                                    <NavMenuLink
                                        key={thematic.hubpage.id}
                                        className={style.columnItem}
                                        href={generatePath('hub_thematic', {
                                            thematicSlug: thematic.hubpage.slug,
                                        })}
                                        onClick={() => {
                                            trackEvent((ampli, defaultProperties) => {
                                                ampli.primaryNavThematicClicked({
                                                    ...defaultProperties,
                                                    block: 'primary_nav',
                                                    card_number: itemsIndex + 1,
                                                    column_number: index + 1,
                                                    row_number: 3,
                                                    thematic_slug: thematic.hubpage.slug,
                                                    click_type: 'link',
                                                    thematic_group: 'what',
                                                });
                                            });
                                        }}
                                    >
                                        {thematic.label}
                                    </NavMenuLink>
                                ))}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
