import cx from 'classnames';
import { useIntl } from 'react-intl';

import { useTrackEventAmplitude } from '../../../../../core/Tracking/Amplitude/Amplitude';
import { PrimaryNavDestinationCard } from '../../../../../design-system/components/card/primary-nav-destination/PrimaryNavDestinationCard';
import { Button } from '../../../../../design-system/components/form/button/Button';
import { IconArrowRight } from '../../../../../design-system/components/icons/ArrowRight';
import { MenuItem } from '../../../../../design-system/components/nav/menu-item/MenuItem';
import {
    Tab,
    TabContent,
    TabList,
    TabRoot,
} from '../../../../../design-system/components/tabs/TabList';
import { Typography } from '../../../../../design-system/components/typography/Typography';
import { DestinationsMenu } from '../../../../../resources/destinationsMenu/domain/types/destinationsMenu';
import { useUrlGenerator } from '../../../../../shared/hooks/useUrlGenerator';
import { useUser } from '../../../../../shared/providers/user/useUser';
import { imageLoaderForId } from '../../../Image/URL/ImageURL';
import { PrimaryNavContextContinent } from '../../types';

import style from './DestinationsContent.module.css';

interface DestinationDropdownProps {
    destinationsMenu: DestinationsMenu;
    contextContinent: PrimaryNavContextContinent | null;
}

const WORLD_VALUE = 'all';

export function DestinationsContent({
    destinationsMenu,
    contextContinent,
}: DestinationDropdownProps) {
    const { formatMessage } = useIntl();
    const { trackEvent } = useTrackEventAmplitude();

    const { world, continents } = destinationsMenu;
    const defaultSelectedTab = contextContinent ? `${contextContinent.slug}` : WORLD_VALUE;
    const orderedContinents = continents.slice().sort((a) => {
        if (!contextContinent) {
            return 0;
        }
        return contextContinent.id === a.continent.id ? -1 : 0;
    });

    return (
        <TabRoot defaultValue={defaultSelectedTab} orientation="horizontal">
            <div className={style.tabListWrapper}>
                <TabList reversed id="DestinationsDropdown-TabList" aria-label="Continents Tabs">
                    <Tab
                        value={WORLD_VALUE}
                        data-testid={tabTestId(null)}
                        onClick={() => {
                            trackEvent((ampli, defaultProperties) => {
                                ampli.primaryNavContinentSelected({
                                    ...defaultProperties,
                                    block: 'primary_nav',
                                    continent: WORLD_VALUE,
                                    card_number: 1,
                                });
                            });
                        }}
                        className={style.tab}
                    >
                        {formatMessage({
                            id: 'global.header.globalNav.destinations.continents.tabs.world',
                        })}
                    </Tab>

                    {orderedContinents.map(({ continent }, index) => (
                        <Tab
                            key={continent.id}
                            value={continent.slug}
                            data-testid={tabTestId(continent)}
                            onClick={() => {
                                trackEvent((ampli, defaultProperties) => {
                                    ampli.primaryNavContinentSelected({
                                        ...defaultProperties,
                                        block: 'primary_nav',
                                        continent: continent.slug,
                                        card_number: index + 2,
                                    });
                                });
                            }}
                            className={style.tab}
                        >
                            {continent.name}
                        </Tab>
                    ))}
                </TabList>
            </div>

            <ContinentTabPanel
                key="all"
                contextContinent={contextContinent}
                continent={null}
                topDestinations={world.topDestinations}
                destinations={[]}
            />

            {orderedContinents.map(({ continent, topDestinations, destinations }) => {
                return (
                    <ContinentTabPanel
                        key={continent.id}
                        contextContinent={contextContinent}
                        continent={continent}
                        topDestinations={topDestinations}
                        destinations={destinations}
                    />
                );
            })}
        </TabRoot>
    );
}

DestinationsContent.WORLD_VALUE = WORLD_VALUE;

function ContinentTabPanel({
    contextContinent,
    continent,
    topDestinations,
    destinations,
}: {
    contextContinent: PrimaryNavContextContinent | null;
    continent: DestinationsMenu['continents'][number]['continent'] | null;
    topDestinations: DestinationsMenu['continents'][number]['topDestinations'];
    destinations: DestinationsMenu['continents'][number]['destinations'];
}) {
    const { generatePath } = useUrlGenerator();
    const { formatMessage } = useIntl();
    const { trackEvent } = useTrackEventAmplitude();

    const forceMount = contextContinent ? undefined : true;
    const slug = continent ? continent.slug : WORLD_VALUE;

    const anchorHref = continent
        ? generatePath('continent', { slug: continent.slug })
        : generatePath('destination_list');

    const anchorText = continent
        ? formatMessage(
              { id: 'global.header.globalNav.destinations.continentPage' },
              {
                  continentPreposition: continent.preposition,
                  continentName: continent.name,
              },
          )
        : formatMessage({
              id: 'global.header.globalNav.destinations.worldPage',
          });

    return (
        <TabContent
            forceMount={forceMount}
            value={slug}
            className={cx(style.tabContent, {
                [style.tabContentForceMount]: forceMount,
            })}
            data-testid={`DestinationsDropdown-TabPanel-${slug}`}
        >
            <TopDestinations continent={continent} destinations={topDestinations} />

            {continent && destinations.length > 0 ? (
                <DestinationsDropdownOtherDestinations
                    continent={continent}
                    destinations={destinations}
                />
            ) : null}

            <div className={style.section}>
                <Button
                    priority="secondary"
                    reversed={true}
                    data-testid={`DestinationsDropdown-TabPanel-Anchor-${slug}`}
                    href={anchorHref}
                    Icon={IconArrowRight}
                    iconPosition="right"
                    onClick={() => {
                        trackEvent((ampli, defaultProperties) => {
                            ampli.primaryNavContinentClicked({
                                ...defaultProperties,
                                block: 'primary_nav',
                                continent: slug,
                            });
                        });
                    }}
                >
                    {anchorText}
                </Button>
            </div>
        </TabContent>
    );
}

type TopDestinationsProps = {
    continent: PrimaryNavContextContinent | null;
    destinations: DestinationsMenu['continents'][number]['topDestinations'];
};

function TopDestinations({ continent, destinations }: TopDestinationsProps) {
    const { generatePath } = useUrlGenerator();
    const { formatMessage } = useIntl();
    const { trackEvent } = useTrackEventAmplitude();
    const { user } = useUser();

    const hasAccessToRecommendation = user?.recommendationsIsEnabled;

    const title = continent
        ? formatMessage(
              {
                  id: 'global.header.globalNav.destinations.topDestinations.continent',
              },
              {
                  continentPreposition: continent.preposition,
                  continentName: continent.name,
              },
          )
        : formatMessage({
              id: 'global.header.globalNav.destinations.topDestinations.world',
          });

    return (
        <div className={style.section}>
            <Typography as="div" scale="primary-s-extra-bold" className={style.sectionTitle}>
                {title}
            </Typography>

            <ul className={style.topDestinationsList}>
                {destinations.map((destination, index) => {
                    return (
                        <li key={destination.slug} className={style.topDestinationsListItem}>
                            <PrimaryNavDestinationCard
                                onClick={() => {
                                    trackEvent((ampli, defaultProperties) => {
                                        ampli.primaryNavTopDestinationClicked({
                                            ...defaultProperties,
                                            destination_id: destination.id,
                                            destination_slug: destination.slug,
                                            block: 'primary_nav',
                                            card_number: index + 1,
                                            continent: continent ? continent.slug : WORLD_VALUE,
                                        });
                                    });
                                }}
                                title={destination.name}
                                image={{
                                    alt: formatMessage(
                                        {
                                            id: 'global.header.globalNav.destinations.destinationAltTitle',
                                        },
                                        {
                                            destinationPreposition: destination.preposition,
                                            destinationName: destination.name,
                                        },
                                    ),
                                    src: destination.coverPicture.id.toString(),
                                    sizes: '(min-width: 1200px) 115px, (min-width: 768px) 130px, (min-width: 576px) 170px, 110px',
                                    loader: imageLoaderForId({
                                        aspectRatio: PrimaryNavDestinationCard.PICTURE_RATIO,
                                    }),
                                }}
                                href={generatePath('destination', {
                                    destinationSlug: destination.slug,
                                })}
                                small={true}
                            />
                        </li>
                    );
                })}
                <li className={style.topDestinationsListItem}>
                    <a
                        className={style.findDestination}
                        onClick={() => {
                            trackEvent((ampli, defaultProperties) => {
                                if (hasAccessToRecommendation) {
                                    ampli.primaryNavDestinationRecommendationClicked({
                                        ...defaultProperties,
                                        block: 'primary_nav',
                                        continent: continent ? continent.slug : WORLD_VALUE,
                                    });
                                    return;
                                }

                                ampli.primaryNavDestinationWheretogoClicked({
                                    ...defaultProperties,
                                    block: 'primary_nav',
                                    continent: continent ? continent.slug : WORLD_VALUE,
                                });
                            });
                        }}
                        href={
                            hasAccessToRecommendation
                                ? generatePath('account_recommendations', {
                                      source: 'top-destinations',
                                  })
                                : generatePath('where_to_go')
                        }
                    >
                        <span className={cx(style.findDestinationText, 'font-xs-bold')}>
                            {formatMessage({
                                id: 'global.header.globalNav.destinations.findDestination',
                            })}
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    );
}

type OtherDestinationsProps = {
    continent: DestinationsMenu['continents'][number]['continent'];
    destinations: DestinationsMenu['continents'][number]['destinations'];
};

function DestinationsDropdownOtherDestinations(props: OtherDestinationsProps) {
    const { continent, destinations } = props;
    const { formatMessage } = useIntl();
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();

    return (
        <div className={style.section}>
            <Typography as="div" scale="primary-s-extra-bold" className={style.sectionTitle}>
                {formatMessage(
                    {
                        id: 'global.header.globalNav.destinations.otherDestinations',
                    },
                    {
                        continentPreposition: continent.preposition,
                        continentName: continent.name,
                    },
                )}
            </Typography>

            <ul className={style.otherDestinationsList}>
                {destinations.map((destination, index) => {
                    return (
                        <li key={destination.slug}>
                            <MenuItem
                                href={generatePath('destination', {
                                    destinationSlug: destination.slug,
                                })}
                                size="small"
                                inline /* inline-flex for Safari compatibility with column-count */
                                onClick={() => {
                                    trackEvent((ampli, defaultProperties) => {
                                        ampli.primaryNavOtherDestinationClicked({
                                            ...defaultProperties,
                                            destination_id: destination.id,
                                            destination_slug: destination.slug,
                                            block: 'primary_nav',
                                            card_number: index + 1,
                                            continent: continent ? continent.slug : WORLD_VALUE,
                                        });
                                    });
                                }}
                                title={formatMessage(
                                    {
                                        id: 'global.header.globalNav.destinations.destinationAltTitle',
                                    },
                                    {
                                        destinationPreposition: destination.preposition,
                                        destinationName: destination.name,
                                    },
                                )}
                            >
                                {destination.name}
                            </MenuItem>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

function tabTestId(continent: DestinationsMenu['continents'][number]['continent'] | null) {
    return `DestinationsDropdown-Tab-${continent ? continent.slug : WORLD_VALUE}`;
}
