import { useTrackEventAmplitude } from '../../../../../../core/Tracking/Amplitude/Amplitude';
import { IconDotDivider } from '../../../../../../design-system/components/icons/DotDivider';
import { NavMenuButton } from '../../../../../../design-system/components/nav/nav-menu-button/NavMenuButton';
import { NavMenuImageCard } from '../../../../../../design-system/components/nav/nav-menu-image-card/NavMenuImageCard';
import { WhereToGoMenu } from '../../../../../../resources/whereToGoMenu/domain/types/whereToGoMenu';
import { useUrlGenerator } from '../../../../../../shared/hooks/useUrlGenerator';

import style from './WhenTabContent.module.css';

type WhenTabContentProps = {
    when: WhereToGoMenu['when'];
};

export function WhenTabContent({ when }: WhenTabContentProps) {
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();

    const [firstHighlightedThematic, ...otherHighlightedThematics] = when.thematicsHighlight;

    return (
        <div className={style.tabContent}>
            {when.thematicsHighlight.length > 0 ? (
                <div className={style.menuImageContainer}>
                    <div className={style.menuImageFirstHighlightedWrapper}>
                        <NavMenuImageCard
                            label={firstHighlightedThematic.label}
                            blur={true}
                            href={generatePath('hub_thematic', {
                                thematicSlug: firstHighlightedThematic.hubpage.slug,
                            })}
                            image={{
                                alt: firstHighlightedThematic.hubpage.coverPicture.card_cover_mobile
                                    .alt,
                                src: firstHighlightedThematic.hubpage.coverPicture.card_cover_mobile
                                    .url,
                                loading: 'lazy',
                                unoptimized: true,
                            }}
                            onClick={() => {
                                trackEvent((ampli, defaultProperties) => {
                                    ampli.primaryNavThematicClicked({
                                        ...defaultProperties,
                                        block: 'primary_nav',
                                        card_number: 1,
                                        row_number: 2,
                                        thematic_slug: firstHighlightedThematic.hubpage.slug,
                                        click_type: 'card',
                                        column_number: 0,
                                        thematic_group: 'when',
                                    });
                                });
                            }}
                        />
                    </div>

                    {otherHighlightedThematics.length > 0 ? (
                        <div className={style.menuImageOtherHighlightedWrapper}>
                            {otherHighlightedThematics
                                .slice(0, 3)
                                .map((thematicHighlight, index) => {
                                    return (
                                        <NavMenuImageCard
                                            key={thematicHighlight.hubpage.id}
                                            label={thematicHighlight.label}
                                            blur={true}
                                            href={generatePath('hub_thematic', {
                                                thematicSlug: thematicHighlight.hubpage.slug,
                                            })}
                                            image={{
                                                alt: thematicHighlight.hubpage.coverPicture
                                                    .card_cover_mobile.alt,
                                                src: thematicHighlight.hubpage.coverPicture
                                                    .card_cover_mobile.url,
                                                loading: 'lazy',
                                                unoptimized: true,
                                            }}
                                            onClick={() => {
                                                trackEvent((ampli, defaultProperties) => {
                                                    ampli.primaryNavThematicClicked({
                                                        ...defaultProperties,
                                                        block: 'primary_nav',
                                                        card_number: index + 2,
                                                        row_number: 2,
                                                        thematic_slug:
                                                            thematicHighlight.hubpage.slug,
                                                        click_type: 'card',
                                                        column_number: 0,
                                                        thematic_group: 'when',
                                                    });
                                                });
                                            }}
                                        />
                                    );
                                })}
                        </div>
                    ) : null}
                </div>
            ) : null}

            {when.thematics.length > 0 ? (
                <div className={style.thematicLinkContainer}>
                    {when.thematics.slice(0, 12).map((thematic, index) => {
                        return (
                            <div className={style.blockDivider} key={thematic.hubpage.id}>
                                <NavMenuButton
                                    href={generatePath('hub_thematic', {
                                        thematicSlug: thematic.hubpage.slug,
                                    })}
                                    onClick={() => {
                                        trackEvent((ampli, defaultProperties) => {
                                            ampli.primaryNavThematicClicked({
                                                ...defaultProperties,
                                                block: 'primary_nav',
                                                card_number: index + 1,
                                                row_number: 2,
                                                thematic_slug: thematic.hubpage.slug,
                                                click_type: 'link',
                                                column_number: 0,
                                                thematic_group: 'when',
                                            });
                                        });
                                    }}
                                >
                                    {thematic.label}
                                </NavMenuButton>

                                {index < when.thematics.length - 1 ? (
                                    <IconDotDivider color="var(--color-yellow-200)" />
                                ) : null}
                            </div>
                        );
                    })}
                </div>
            ) : null}
        </div>
    );
}
