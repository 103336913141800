import React, { PropsWithChildren } from 'react';

import { MobileMenuBottomLinks } from '../BottomLinks/MobileMenuBottomLinks';

import { MobileMenuTitle } from './MobileMenuTitle/MobileMenuTitle';
import styles from './MobileView.module.css';
import { MobileViewSeparator } from './Separator/MobileViewSeparator';

export function MobileView({ title, children }: PropsWithChildren<{ title: string | null }>) {
    return (
        <div className={styles.mobileView}>
            <>
                {title ? <MobileMenuTitle title={title} /> : null}
                {children}
                <MobileViewSeparator />
                <MobileMenuBottomLinks />
            </>
        </div>
    );
}
