import cx from 'classnames';
import Image, { ImageProps } from 'next/image';

import { Typography } from '../../typography/Typography';

import { GrainSVG } from './GrainSVG';
import style from './NavMenuImageCard.module.css';

type NavMenuImageCardProps = {
    image: ImageProps;
    label: string;
    href: string;
    blur?: boolean;
    obfuscate?: boolean;
    onClick?: () => void;
    aspectRatio?: number;
};

export function NavMenuImageCard({
    image,
    label,
    href,
    blur = false,
    obfuscate = false,
    onClick,
    aspectRatio,
}: NavMenuImageCardProps) {
    const HtmlTag = obfuscate ? 'div' : 'a';

    return (
        <HtmlTag
            style={{ aspectRatio }}
            className={cx(style.card, { [style.blurredImage]: blur })}
            href={obfuscate ? undefined : href}
            onClick={(event) => {
                if (onClick) {
                    onClick();
                }

                if (!obfuscate) {
                    return;
                }
                if (event.ctrlKey) {
                    return window.open(href, '_blank');
                }

                window.location.assign(href);
            }}
        >
            <Image {...image} alt={image.alt} fill className={style.image} />
            <div className={style.blackOverlay} />
            <div className={style.grainOverlay}>
                <GrainSVG />
            </div>
            <Typography as="span" className={style.label} scale="primary-body-bold">
                {label}
            </Typography>
        </HtmlTag>
    );
}
