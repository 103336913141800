import { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';

import { useTrackEventAmplitude } from '../../../../../../core/Tracking/Amplitude/Amplitude';
import { Button } from '../../../../../../design-system/components/form/button/Button';
import { NavMenuImageCard } from '../../../../../../design-system/components/nav/nav-menu-image-card/NavMenuImageCard';
import { RATIO } from '../../../../../../design-system/components/ratio/Ratio';
import { NormalizedWhereToGoMenu } from '../../../../../../resources/whereToGoMenu/api/prismic/normalizers';
import { useUrlGenerator } from '../../../../../../shared/hooks/useUrlGenerator';
import { PrimaryNavThematicCategoryValue, PrimaryNavValue } from '../../../types';
import { MobileSubNav } from '../MobileSubNav/MobileSubNav';

import style from './WhoView.module.css';

export function WhoView({
    who,
    onNavValueChange,
    setCurrentThematicCategory,
}: {
    who: NonNullable<NonNullable<NormalizedWhereToGoMenu>['who']>;
    onNavValueChange: (value: PrimaryNavValue) => void;
    setCurrentThematicCategory: Dispatch<SetStateAction<PrimaryNavThematicCategoryValue>>;
}) {
    const { formatMessage } = useIntl();
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();

    return (
        <div>
            <MobileSubNav
                title={who.title}
                showPreviousButton={true}
                onPrevious={() => {
                    onNavValueChange('WHERE_TO_GO');
                    setCurrentThematicCategory(null);
                    trackEvent((ampli, defaultProperties) => {
                        ampli.primaryNavPreviousStepClicked({
                            ...defaultProperties,
                            block: 'primary_nav',
                        });
                    });
                }}
            />
            <div className={style.grid}>
                {who.thematics.slice(0, 5).map((thematic, index) => (
                    <NavMenuImageCard
                        key={thematic.hubpage.id}
                        label={thematic.label}
                        blur={false}
                        aspectRatio={RATIO['1/1']}
                        href={generatePath('hub_thematic', {
                            thematicSlug: thematic.hubpage.slug,
                        })}
                        image={{
                            alt: thematic.hubpage.coverPicture.card_cover_mobile_retina.alt,
                            src: thematic.hubpage.coverPicture.card_cover_mobile_retina.url,
                            loading: 'lazy',
                            unoptimized: true,
                        }}
                        onClick={() => {
                            trackEvent((ampli, defaultProperties) => {
                                ampli.primaryNavThematicClicked({
                                    ...defaultProperties,
                                    block: 'primary_nav',
                                    card_number: index + 1,
                                    row_number: 1,
                                    thematic_slug: thematic.hubpage.slug,
                                    click_type: 'card',
                                    column_number: 0,
                                    thematic_group: 'who',
                                });
                            });
                        }}
                    />
                ))}
            </div>

            <div className={style.footer}>
                <Button
                    priority="secondary"
                    reversed
                    href={generatePath('where_to_go')}
                    onClick={() => {
                        trackEvent((ampli, defaultProperties) => {
                            ampli.primaryNavWheretogoClicked({
                                ...defaultProperties,
                                block: 'primary_nav',
                            });
                        });
                    }}
                >
                    {formatMessage({
                        id: 'global.header.globalNav.new.whereToGo.cta',
                    })}
                </Button>
            </div>
        </div>
    );
}
