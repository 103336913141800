import { HubDestinationMenu } from '../../../../../../resources/hubDestinationMenu/domain/types/hubDestinationMenu';
import { Container } from '../../../../Container/Container';
import { MobileView } from '../MobileView';

import { HubDestinationContent } from './HubDestinationContent/HubDestinationContent';

export function HubDestinationMobileView({
    hubDestinationMenu,
}: {
    hubDestinationMenu: HubDestinationMenu;
}) {
    return (
        <MobileView title={hubDestinationMenu.destination.name}>
            <Container>
                <HubDestinationContent hubDestinationMenu={hubDestinationMenu} />
            </Container>
        </MobileView>
    );
}
