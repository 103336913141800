import cx from 'classnames';

import { cloneElement, ComponentProps, forwardRef, ReactElement } from 'react';

import { Typography } from '../../typography/Typography';

import style from './NavBarItem.module.css';

type NavBarItemProps = ComponentProps<'a' | 'button'> & {
    iconLeading?: ReactElement<{ className?: string }>;
    iconTrailing?: ReactElement<{ className?: string }>;
    isMenuOpen: boolean;
    as: 'a' | 'button';
    displayDesktopIconLeading?: boolean;
};

export const NavBarItem = forwardRef<HTMLAnchorElement | HTMLButtonElement, NavBarItemProps>(
    (
        {
            className,
            children,
            iconLeading,
            iconTrailing,
            isMenuOpen,
            displayDesktopIconLeading,
            as,
            ...props
        },
        ref,
    ) => {
        return (
            <Typography
                as={as}
                {...props}
                ref={ref}
                className={cx(className, style.navBarItem, {
                    [style.isMenuOpen]: isMenuOpen,
                    [style.displayDesktopIconLeading]: displayDesktopIconLeading,
                })}
                scale="primary-xs-bold"
            >
                {iconLeading
                    ? cloneElement(iconLeading, {
                          ...iconLeading.props,
                          className: cx(iconLeading.props.className, style.iconLeading),
                      })
                    : null}
                <span className={style.text}>{children}</span>
                {iconTrailing
                    ? cloneElement(iconTrailing, {
                          ...iconTrailing.props,
                          className: cx(iconTrailing.props.className, style.iconTrailing),
                      })
                    : null}
            </Typography>
        );
    },
);
