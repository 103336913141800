import { useTrackEventAmplitude } from '../../../../../../core/Tracking/Amplitude/Amplitude';
import { NavMenuImageCard } from '../../../../../../design-system/components/nav/nav-menu-image-card/NavMenuImageCard';
import { WhereToGoMenu } from '../../../../../../resources/whereToGoMenu/domain/types/whereToGoMenu';
import { useUrlGenerator } from '../../../../../../shared/hooks/useUrlGenerator';

import style from './WhoTabContent.module.css';

type WhoTabContentProps = {
    who: WhereToGoMenu['who'];
};

export function WhoTabContent({ who }: WhoTabContentProps) {
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();

    return (
        <div className={style.tabContent}>
            {who.thematics.slice(0, 5).map((thematic, index) => (
                <NavMenuImageCard
                    key={thematic.hubpage.id}
                    label={thematic.label}
                    blur={false}
                    href={generatePath('hub_thematic', {
                        thematicSlug: thematic.hubpage.slug,
                    })}
                    image={{
                        alt: thematic.hubpage.coverPicture.card_cover_mobile_retina.alt,
                        src: thematic.hubpage.coverPicture.card_cover_mobile_retina.url,
                        loading: 'lazy',
                        unoptimized: true,
                    }}
                    onClick={() => {
                        trackEvent((ampli, defaultProperties) => {
                            ampli.primaryNavThematicClicked({
                                ...defaultProperties,
                                block: 'primary_nav',
                                card_number: index + 1,
                                row_number: 1,
                                thematic_slug: thematic.hubpage.slug,
                                click_type: 'card',
                                column_number: 0,
                                thematic_group: 'who',
                            });
                        });
                    }}
                />
            ))}
        </div>
    );
}
