import { useIntl } from 'react-intl';

import { DestinationsMenu } from '../../../../../../resources/destinationsMenu/domain/types/destinationsMenu';
import { Container } from '../../../../Container/Container';
import { DestinationsContent } from '../../../Shared/Destinations/DestinationsContent';
import { PrimaryNavContextContinent } from '../../../types';
import { MobileView } from '../MobileView';

type Props = {
    contextContinent: PrimaryNavContextContinent | null;
    destinationsMenu: DestinationsMenu;
};

export function DestinationsMobileView({ contextContinent, destinationsMenu }: Props) {
    const { formatMessage } = useIntl();

    return (
        <MobileView title={formatMessage({ id: 'global.header.globalNav.destinations.label' })}>
            <Container>
                <DestinationsContent
                    contextContinent={contextContinent}
                    destinationsMenu={destinationsMenu}
                />
            </Container>
        </MobileView>
    );
}
