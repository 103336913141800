import { SVGAttributes } from 'react';

type Props = SVGAttributes<SVGElement>;

export function IconDotDivider(props: Props) {
    return (
        <svg
            width="4"
            height="4"
            viewBox="0 0 4 4"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx="2" cy="2" r="2" />
        </svg>
    );
}
