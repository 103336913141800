import React, { HTMLAttributes, RefObject } from 'react';

import { DestinationsMenu } from '../../../../../resources/destinationsMenu/domain/types/destinationsMenu';
import { DestinationsContent } from '../../Shared/Destinations/DestinationsContent';
import { PrimaryNavContextContinent } from '../../types';
import { DropdownContent } from '../DropdownContent/DropdownContents';

import style from './DestinationsDropdownContent.module.css';

type DestinationDropdownProps = HTMLAttributes<HTMLDivElement> & {
    destinationsMenu: DestinationsMenu;
    contextContinent: PrimaryNavContextContinent | null;
    onClickOutside: () => void;
    triggerRef: RefObject<HTMLElement | null>;
};

export function DestinationsDropdownContent({
    destinationsMenu,
    contextContinent,
    ...props
}: DestinationDropdownProps) {
    return (
        <DropdownContent {...props}>
            <div className={style.container}>
                <DestinationsContent
                    destinationsMenu={destinationsMenu}
                    contextContinent={contextContinent}
                />
            </div>
        </DropdownContent>
    );
}
