import { useTrackEventAmplitude } from '../../../../../core/Tracking/Amplitude/Amplitude';
import {
    CommitmentLink,
    useCommitmentsLinks,
} from '../../../../../shared/hooks/useCommitmentsLinks';

export function usePrimaryNavCommitmentsLinks(): Array<
    CommitmentLink & {
        trackClick: () => void;
    }
> {
    const { trackEvent } = useTrackEventAmplitude();
    const { concept, impact, carbonNeutral, bCorp, impactReport } = useCommitmentsLinks();

    return [
        {
            ...concept,
            trackClick: () => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.primaryNavConceptClicked({
                        ...defaultProperties,
                        block: 'primary_nav',
                    });
                });
            },
        },
        {
            ...impact,
            trackClick: () => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.primaryNavOurImpactClicked({
                        ...defaultProperties,
                        block: 'primary_nav',
                    });
                });
            },
        },
        {
            ...carbonNeutral,
            trackClick: () => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.primaryNavCarbonNeutralClicked({
                        ...defaultProperties,
                        block: 'primary_nav',
                    });
                });
            },
        },
        {
            ...bCorp,
            trackClick: () => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.primaryNavBCorpClicked({
                        ...defaultProperties,
                        block: 'primary_nav',
                    });
                });
            },
        },
        {
            ...impactReport,
            trackClick: () => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.primaryNavImpactReportClicked({
                        ...defaultProperties,
                        block: 'primary_nav',
                    });
                });
            },
        },
    ];
}
