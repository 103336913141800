import React from 'react';
import { useIntl } from 'react-intl';

import { useTrackEventAmplitude } from '../../../../../../../core/Tracking/Amplitude/Amplitude';
import { Button } from '../../../../../../../design-system/components/form/button/Button';
import { NavMenuLink } from '../../../../../../../design-system/components/nav/nav-menu-link/NavMenuLink';
import { HubDestinationMenu } from '../../../../../../../resources/hubDestinationMenu/domain/types/hubDestinationMenu';
import { useUrlGenerator } from '../../../../../../../shared/hooks/useUrlGenerator';
import { MobileViewSeparator } from '../../Separator/MobileViewSeparator';

import style from './HubDestinationContent.module.css';
import { HubDestinationRegions } from './HubDestinationRegions';

type HubDestinationContentProps = {
    hubDestinationMenu: HubDestinationMenu;
};

export function HubDestinationContent({ hubDestinationMenu }: HubDestinationContentProps) {
    const {
        destination,
        regions,
        destinationsTypeRegion,
        hasItineraries,
        hasPointsOfInterest,
        hasAgencies,
        hasMainWhenToGo,
        hasDestinationTripReviews,
    } = hubDestinationMenu;
    const hasRegions = regions.length > 0 || destinationsTypeRegion.length > 0;

    return (
        <>
            {hasItineraries ? <ItineraryListCta destination={destination} /> : null}

            <div className={style.mobileMenuList}>
                <DestinationLink destination={hubDestinationMenu.destination} />

                {hasPointsOfInterest ? <PointOfInterestLink destination={destination} /> : null}

                {hasMainWhenToGo ? <WhenToGoLink destination={destination} /> : null}

                {hasAgencies ? <AgenciesLink destination={destination} /> : null}

                {destination.hasCommunityContent ? (
                    <TravelGuideLink destination={destination} />
                ) : null}

                {hasDestinationTripReviews ? (
                    <DestinationTripReviewsLink destination={destination} />
                ) : null}
            </div>

            {hasRegions ? (
                <>
                    <MobileViewSeparator />
                    <HubDestinationRegions hubDestinationMenu={hubDestinationMenu} />
                </>
            ) : null}
        </>
    );
}

function DestinationLink({ destination }: { destination: HubDestinationMenu['destination'] }) {
    const { formatMessage } = useIntl();
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();

    return (
        <NavMenuLink
            href={generatePath('destination', {
                destinationSlug: destination.slug,
            })}
            onClick={() => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.primaryNavDestinationClicked({
                        ...defaultProperties,
                        block: 'primary_nav',
                        destination_id: destination.id,
                        destination_slug: destination.slug,
                        destination_type: destination.parentDestination ? 'region' : 'country',
                        click_type: 'link',
                    });
                });
            }}
        >
            {formatMessage(
                { id: 'global.header.globalNav.hubDestination.destination' },
                {
                    destinationPreposition: destination.preposition,
                    destinationName: destination.name,
                },
            )}
        </NavMenuLink>
    );
}

function TravelGuideLink({ destination }: { destination: HubDestinationMenu['destination'] }) {
    const { formatMessage } = useIntl();
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();

    return (
        <NavMenuLink
            href={generatePath('travel_guide', {
                destinationSlug: destination.slug,
            })}
            onClick={() => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.primaryNavTravelGuideClicked({
                        ...defaultProperties,
                        block: 'primary_nav',
                        destination_id: destination.id,
                        destination_slug: destination.slug,
                        destination_type: destination.parentDestination ? 'region' : 'country',
                    });
                });
            }}
        >
            {formatMessage(
                { id: 'global.header.globalNav.hubDestination.travelGuide' },
                {
                    destinationPreposition: destination.preposition,
                    destinationName: destination.name,
                },
            )}
        </NavMenuLink>
    );
}

function WhenToGoLink({ destination }: { destination: HubDestinationMenu['destination'] }) {
    const { generatePath } = useUrlGenerator();
    const { formatMessage } = useIntl();
    const { trackEvent } = useTrackEventAmplitude();

    return (
        <NavMenuLink
            href={generatePath('destination_when_to_go', {
                destinationSlug: destination.slug,
            })}
            onClick={() => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.primaryNavWhentogoClicked({
                        ...defaultProperties,
                        block: 'primary_nav',
                        destination_id: destination.id,
                        destination_slug: destination.slug,
                        destination_type: destination.parentDestination ? 'region' : 'country',
                    });
                });
            }}
        >
            {formatMessage(
                { id: 'global.header.globalNav.hubDestination.whenToGo' },
                {
                    destinationPreposition: destination.preposition,
                    destinationName: destination.name,
                },
            )}
        </NavMenuLink>
    );
}

function ItineraryListCta({ destination }: { destination: HubDestinationMenu['destination'] }) {
    const { formatMessage } = useIntl();
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();

    return (
        <div className={style.itineraryListCta}>
            <Button
                priority="tertiary"
                reversed={true}
                href={generatePath('itinerary_list', {
                    destinationSlug: destination.slug,
                })}
                onClick={() => {
                    trackEvent((ampli, defaultProperties) => {
                        ampli.primaryNavItinerariesListClicked({
                            ...defaultProperties,
                            destination_id: destination.id,
                            destination_slug: destination.slug,
                            destination_type: destination.parentDestination ? 'region' : 'country',
                            block: 'primary_nav',
                        });
                    });
                }}
            >
                {formatMessage(
                    { id: 'global.cta.listItineraries' },
                    {
                        destinationPreposition: destination.preposition,
                        destinationName: destination.name,
                        itineraryCount: 0,
                    },
                )}
            </Button>
        </div>
    );
}

function AgenciesLink({ destination }: { destination: HubDestinationMenu['destination'] }) {
    const { formatMessage } = useIntl();
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();

    return (
        <NavMenuLink
            href={generatePath('agency_list', {
                destinationSlug: destination.slug,
            })}
            onClick={() => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.primaryNavAgenciesListClicked({
                        ...defaultProperties,
                        block: 'primary_nav',
                        destination_id: destination.id,
                        destination_slug: destination.slug,
                        destination_type: destination.parentDestination ? 'region' : 'country',
                    });
                });
            }}
        >
            {formatMessage(
                { id: 'global.header.globalNav.hubDestination.agencies' },
                {
                    destinationPreposition: destination.preposition,
                    destinationName: destination.name,
                },
            )}
        </NavMenuLink>
    );
}

function PointOfInterestLink({ destination }: { destination: HubDestinationMenu['destination'] }) {
    const { formatMessage } = useIntl();
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();

    return (
        <NavMenuLink
            href={generatePath('point_of_interest_list', {
                destinationSlug: destination.slug,
            })}
            onClick={() => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.primaryNavPoiListClicked({
                        ...defaultProperties,
                        block: 'primary_nav',
                        destination_id: destination.id,
                        destination_slug: destination.slug,
                        destination_type: destination.parentDestination ? 'region' : 'country',
                    });
                });
            }}
        >
            {formatMessage(
                { id: 'global.header.globalNav.hubDestination.pointOfInterest' },
                {
                    destinationPreposition: destination.preposition,
                    destinationName: destination.name,
                },
            )}
        </NavMenuLink>
    );
}

function DestinationTripReviewsLink({
    destination,
}: {
    destination: HubDestinationMenu['destination'];
}) {
    const { formatMessage } = useIntl();
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();

    return (
        <NavMenuLink
            href={generatePath('trip_review_list_destination', {
                destinationSlug: destination.slug,
            })}
            onClick={() => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.primaryNavTestimonialClicked({
                        ...defaultProperties,
                        block: 'primary_nav',
                        destination_id: destination.id,
                        destination_slug: destination.slug,
                        destination_type: destination.parentDestination ? 'region' : 'country',
                    });
                });
            }}
        >
            {formatMessage({ id: 'global.header.globalNav.hubDestination.tripReviews' })}
        </NavMenuLink>
    );
}
