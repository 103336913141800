import React from 'react';

import { useIntl } from 'react-intl';

import { useTrackEventAmplitude } from '../../../../../../../core/Tracking/Amplitude/Amplitude';
import { SquareCard } from '../../../../../../../design-system/components/card/square/SquareCard';
import { RATIO } from '../../../../../../../design-system/components/ratio/Ratio';
import { Typography } from '../../../../../../../design-system/components/typography/Typography';
import { HubDestinationMenu } from '../../../../../../../resources/hubDestinationMenu/domain/types/hubDestinationMenu';
import { useUrlGenerator } from '../../../../../../../shared/hooks/useUrlGenerator';
import { imageLoaderForPicture } from '../../../../../Image/URL/ImageURL';

import style from './HubDestinationRegions.module.css';

export function HubDestinationRegions({
    hubDestinationMenu,
}: {
    hubDestinationMenu: HubDestinationMenu;
}) {
    const { formatMessage } = useIntl();
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();

    const { destination, destinationsTypeRegion, regions } = hubDestinationMenu;
    const country = destination.parentDestination ?? destination;

    const title =
        destination === country
            ? formatMessage(
                  { id: 'global.header.globalNav.hubDestination.regions.title' },
                  {
                      destinationPreposition: country.preposition,
                      destinationName: country.name,
                  },
              )
            : formatMessage(
                  {
                      id: 'global.header.globalNav.hubDestination.regions.destinationTypeRegionTitle',
                  },
                  {
                      destinationName: country.name,
                  },
              );

    return (
        <div>
            <Typography className={style.title} as="div" scale="primary-s-extra-bold">
                {title}
            </Typography>

            <div className={style.carousel}>
                <div className={style.scroller}>
                    {destination.parentDestination ? (
                        <div className={style.slide}>
                            <a
                                className={style.findDestination}
                                href={generatePath('destination', {
                                    destinationSlug: destination.parentDestination.slug,
                                })}
                                onClick={() => {
                                    trackEvent((ampli, defaultProperties) => {
                                        ampli.primaryNavDestinationClicked({
                                            ...defaultProperties,
                                            block: 'primary_nav',
                                            destination_id: destination.parentDestination.id,
                                            destination_slug: destination.parentDestination.slug,
                                            destination_type: 'country',
                                            click_type: 'card',
                                        });
                                    });
                                }}
                            >
                                <Typography
                                    as="span"
                                    scale="primary-xs-bold"
                                    className={style.findDestinationText}
                                >
                                    {destination.parentDestination.name}
                                </Typography>
                            </a>
                        </div>
                    ) : null}

                    {destinationsTypeRegion.map((destinationTypeRegion) => {
                        return (
                            <div key={destinationTypeRegion.id} className={style.slide}>
                                <SquareCard
                                    small={true}
                                    title={destinationTypeRegion.name}
                                    href={generatePath('destination', {
                                        destinationSlug: destinationTypeRegion.slug,
                                    })}
                                    image={{
                                        alt: '',
                                        sizes: '90px',
                                        src: destinationTypeRegion.coverPicture.id,
                                        loader: imageLoaderForPicture(
                                            destinationTypeRegion.coverPicture,
                                            {
                                                aspectRatio: RATIO['1/1'],
                                            },
                                        ),
                                    }}
                                    onClick={() => {
                                        trackEvent((ampli, defaultProperties) => {
                                            ampli.primaryNavDestinationClicked({
                                                ...defaultProperties,
                                                block: 'primary_nav',
                                                destination_id: destinationTypeRegion.id,
                                                destination_slug: destinationTypeRegion.slug,
                                                destination_type: 'region',
                                                click_type: 'card',
                                            });
                                        });
                                    }}
                                />
                            </div>
                        );
                    })}

                    {regions.map((region, index) => {
                        const picture = region.coverPicture ?? country.coverPicture;

                        return (
                            <div key={region.id} className={style.slide}>
                                <SquareCard
                                    small={true}
                                    title={region.name}
                                    href={generatePath('region', {
                                        destinationSlug: country.slug,
                                        regionSlug: region.slug,
                                    })}
                                    image={{
                                        alt: '',
                                        sizes: '90px',
                                        src: picture.id,
                                        loader: imageLoaderForPicture(picture, {
                                            aspectRatio: RATIO['1/1'],
                                        }),
                                    }}
                                    onClick={() => {
                                        trackEvent((ampli, defaultProperties) => {
                                            ampli.primaryNavRegionClicked({
                                                ...defaultProperties,
                                                block: 'primary_nav',
                                                region_id: region.id,
                                                region_slug: region.slug,
                                                card_number: index + 1,
                                            });
                                        });
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
