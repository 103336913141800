import cx from 'classnames';
import Image from 'next/image';
import React, { HTMLAttributes, RefObject } from 'react';
import { useIntl } from 'react-intl';

import { useTrackEventAmplitude } from '../../../../../core/Tracking/Amplitude/Amplitude';
import { Button } from '../../../../../design-system/components/form/button/Button';
import { IconArrowRight } from '../../../../../design-system/components/icons/ArrowRight';
import { IconDotDivider } from '../../../../../design-system/components/icons/DotDivider';
import { NavMenuArrowButton } from '../../../../../design-system/components/nav/nav-menu-arrow-button/NavMenuArrowButton';
import { NavMenuButton } from '../../../../../design-system/components/nav/nav-menu-button/NavMenuButton';
import { RATIO } from '../../../../../design-system/components/ratio/Ratio';
import { Typography } from '../../../../../design-system/components/typography/Typography';
import { HubDestinationMenu } from '../../../../../resources/hubDestinationMenu/domain/types/hubDestinationMenu';
import { useUrlGenerator } from '../../../../../shared/hooks/useUrlGenerator';
import { imageLoaderForPicture } from '../../../Image/URL/ImageURL';
import { DropdownContent } from '../DropdownContent/DropdownContents';

import style from './HubDestinationDropdownContent.module.css';

type HubDestinationDropdownContentProps = HTMLAttributes<HTMLDivElement> & {
    hubDestinationMenu: HubDestinationMenu;
    onClickOutside: () => void;
    triggerRef: RefObject<HTMLElement | null>;
};

export function HubDestinationDropdownContent({
    hubDestinationMenu,
    ...props
}: HubDestinationDropdownContentProps) {
    const { hasItineraries } = hubDestinationMenu;
    const hasRegions =
        hubDestinationMenu.regions.length > 0 ||
        hubDestinationMenu.destinationsTypeRegion.length > 0;

    return (
        <DropdownContent {...props}>
            <div
                className={cx(style.container, {
                    [style.hasItineraries]: hasItineraries,
                })}
            >
                <div className={style.main}>
                    <DestinationBlock hubDestinationMenu={hubDestinationMenu} />

                    {hasRegions ? (
                        <>
                            <Separator />
                            <RegionsBlock hubDestinationMenu={hubDestinationMenu} />
                        </>
                    ) : null}
                </div>

                {hasItineraries ? (
                    <div className={style.side}>
                        <SideBlock hubDestinationMenu={hubDestinationMenu} />
                    </div>
                ) : null}
            </div>
        </DropdownContent>
    );
}

function DestinationBlock({ hubDestinationMenu }: { hubDestinationMenu: HubDestinationMenu }) {
    const { formatMessage } = useIntl();
    const { generatePath } = useUrlGenerator();
    const { destination } = hubDestinationMenu;
    const { slug, preposition, name, id, parentDestination } = destination;
    const { trackEvent } = useTrackEventAmplitude();

    return (
        <div className={style.blockDestination}>
            <Typography className={style.blockTitle} as="div" scale="secondary-xxs-regular">
                {name}
            </Typography>

            <ul className={style.blockDestinationLinksList}>
                <li className={style.blockDestinationLinksListItem}>
                    <NavMenuArrowButton
                        href={generatePath('destination', { destinationSlug: slug })}
                        onClick={() => {
                            trackEvent((ampli, defaultProperties) => {
                                ampli.primaryNavDestinationClicked({
                                    ...defaultProperties,
                                    block: 'primary_nav',
                                    destination_id: id,
                                    destination_slug: slug,
                                    destination_type: parentDestination ? 'region' : 'country',
                                    click_type: 'link',
                                });
                            });
                        }}
                    >
                        {formatMessage(
                            {
                                id: 'global.header.globalNav.hubDestination.destination',
                            },
                            {
                                destinationPreposition: preposition,
                                destinationName: name,
                            },
                        )}
                    </NavMenuArrowButton>
                </li>

                {hubDestinationMenu.hasPointsOfInterest ? (
                    <li className={style.blockDestinationLinksListItem}>
                        <NavMenuArrowButton
                            href={generatePath('point_of_interest_list', { destinationSlug: slug })}
                            onClick={() => {
                                trackEvent((ampli, defaultProperties) => {
                                    ampli.primaryNavPoiListClicked({
                                        ...defaultProperties,
                                        block: 'primary_nav',
                                        destination_id: id,
                                        destination_slug: slug,
                                        destination_type: parentDestination ? 'region' : 'country',
                                    });
                                });
                            }}
                        >
                            {formatMessage(
                                { id: 'global.header.globalNav.hubDestination.pointOfInterest' },
                                {
                                    destinationPreposition: preposition,
                                    destinationName: name,
                                },
                            )}
                        </NavMenuArrowButton>
                    </li>
                ) : null}

                {hubDestinationMenu.hasMainWhenToGo ? (
                    <li className={style.blockDestinationLinksListItem}>
                        <NavMenuArrowButton
                            href={generatePath('destination_when_to_go', { destinationSlug: slug })}
                            onClick={() => {
                                trackEvent((ampli, defaultProperties) => {
                                    ampli.primaryNavWhentogoClicked({
                                        ...defaultProperties,
                                        block: 'primary_nav',
                                        destination_id: id,
                                        destination_slug: slug,
                                        destination_type: parentDestination ? 'region' : 'country',
                                    });
                                });
                            }}
                        >
                            {formatMessage(
                                { id: 'global.header.globalNav.hubDestination.whenToGo' },
                                {
                                    destinationPreposition: preposition,
                                    destinationName: name,
                                },
                            )}
                        </NavMenuArrowButton>
                    </li>
                ) : null}

                {hubDestinationMenu.hasAgencies ? (
                    <li className={style.blockDestinationLinksListItem}>
                        <NavMenuArrowButton
                            href={generatePath('agency_list', { destinationSlug: slug })}
                            onClick={() => {
                                trackEvent((ampli, defaultProperties) => {
                                    ampli.primaryNavAgenciesListClicked({
                                        ...defaultProperties,
                                        block: 'primary_nav',
                                        destination_id: id,
                                        destination_slug: slug,
                                        destination_type: parentDestination ? 'region' : 'country',
                                    });
                                });
                            }}
                        >
                            {formatMessage(
                                { id: 'global.header.globalNav.hubDestination.agencies' },
                                {
                                    destinationPreposition: preposition,
                                    destinationName: name,
                                },
                            )}
                        </NavMenuArrowButton>
                    </li>
                ) : null}

                {hubDestinationMenu.destination.hasCommunityContent ? (
                    <li className={style.blockDestinationLinksListItem}>
                        <NavMenuArrowButton
                            href={generatePath('travel_guide', { destinationSlug: slug })}
                            onClick={() => {
                                trackEvent((ampli, defaultProperties) => {
                                    ampli.primaryNavTravelGuideClicked({
                                        ...defaultProperties,
                                        block: 'primary_nav',
                                        destination_id: id,
                                        destination_slug: slug,
                                        destination_type: parentDestination ? 'region' : 'country',
                                    });
                                });
                            }}
                        >
                            {formatMessage(
                                { id: 'global.header.globalNav.hubDestination.travelGuide' },
                                {
                                    destinationPreposition: preposition,
                                    destinationName: name,
                                },
                            )}
                        </NavMenuArrowButton>
                    </li>
                ) : null}

                {hubDestinationMenu.hasDestinationTripReviews ? (
                    <li className={style.blockDestinationLinksListItem}>
                        <NavMenuArrowButton
                            href={generatePath('trip_review_list_destination', {
                                destinationSlug: slug,
                            })}
                            onClick={() => {
                                trackEvent((ampli, defaultProperties) => {
                                    ampli.primaryNavTestimonialClicked({
                                        ...defaultProperties,
                                        block: 'primary_nav',
                                        destination_id: id,
                                        destination_slug: slug,
                                        destination_type: parentDestination ? 'region' : 'country',
                                    });
                                });
                            }}
                        >
                            {formatMessage({
                                id: 'global.header.globalNav.hubDestination.tripReviews',
                            })}
                        </NavMenuArrowButton>
                    </li>
                ) : null}
            </ul>
        </div>
    );
}

function RegionsBlock({ hubDestinationMenu }: { hubDestinationMenu: HubDestinationMenu }) {
    const { formatMessage } = useIntl();
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();

    const { destination, destinationsTypeRegion, regions } = hubDestinationMenu;
    const country = destination.parentDestination ?? destination;

    return (
        <div className={style.blockRegions}>
            <Typography className={style.blockTitle} as="div" scale="secondary-xxs-regular">
                {formatMessage(
                    { id: 'global.header.globalNav.hubDestination.regions.title' },
                    {
                        destinationPreposition: country.preposition,
                        destinationName: country.name,
                    },
                )}
            </Typography>

            <div className={style.navButtonRegionLinkContainer}>
                {destinationsTypeRegion.map((destinationTypeRegion, index) => {
                    return (
                        <div className={style.blockDivider} key={destinationTypeRegion.id}>
                            <NavMenuButton
                                href={generatePath('destination', {
                                    destinationSlug: destinationTypeRegion.slug,
                                })}
                                onClick={() => {
                                    trackEvent((ampli, defaultProperties) => {
                                        ampli.primaryNavDestinationClicked({
                                            ...defaultProperties,
                                            block: 'primary_nav',
                                            destination_id: destinationTypeRegion.id,
                                            destination_slug: destinationTypeRegion.slug,
                                            destination_type: 'region',
                                            click_type: 'link',
                                        });
                                    });
                                }}
                            >
                                {destinationTypeRegion.name}
                            </NavMenuButton>

                            {regions.length > 0 || index < destinationsTypeRegion.length - 1 ? (
                                <IconDotDivider color="var(--color-yellow-200)" />
                            ) : null}
                        </div>
                    );
                })}

                {regions.map((region, index) => {
                    return (
                        <div className={style.blockDivider} key={region.id}>
                            <NavMenuButton
                                href={generatePath('region', {
                                    regionSlug: region.slug,
                                    destinationSlug: country.slug,
                                })}
                                onClick={() => {
                                    trackEvent((ampli, defaultProperties) => {
                                        ampli.primaryNavRegionClicked({
                                            ...defaultProperties,
                                            block: 'primary_nav',
                                            region_id: region.id,
                                            region_slug: region.slug,
                                            card_number: index + 1,
                                        });
                                    });
                                }}
                            >
                                {region.name}
                            </NavMenuButton>

                            {index < regions.length - 1 ? (
                                <IconDotDivider color="var(--color-yellow-200)" />
                            ) : null}
                        </div>
                    );
                })}
            </div>

            {destination.parentDestination ? (
                <div className={style.destinationButton}>
                    <Button
                        type="button"
                        priority="text"
                        reversed
                        href={generatePath('destination', {
                            destinationSlug: destination.parentDestination.slug,
                        })}
                        onClick={() => {
                            trackEvent((ampli, defaultProperties) => {
                                ampli.primaryNavDestinationClicked({
                                    ...defaultProperties,
                                    block: 'primary_nav',
                                    destination_id: destination.parentDestination.id,
                                    destination_slug: destination.parentDestination.slug,
                                    destination_type: 'country',
                                    click_type: 'button',
                                });
                            });
                        }}
                    >
                        {formatMessage(
                            { id: 'global.header.globalNav.hubDestination.parentDestination.cta' },
                            {
                                destinationPreposition: destination.parentDestination.preposition,
                                destinationName: destination.parentDestination.name,
                            },
                        )}
                    </Button>
                </div>
            ) : null}
        </div>
    );
}

function SideBlock({ hubDestinationMenu }: { hubDestinationMenu: HubDestinationMenu }) {
    const { formatMessage } = useIntl();
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();
    const { destination } = hubDestinationMenu;
    const { coverPicture } = destination;
    const itineraryListPageUrl = generatePath('itinerary_list', {
        destinationSlug: destination.slug,
    });

    return (
        <div className={style.destinationHighlightCard}>
            <Image
                alt=""
                className={style.destinationHighlightCardImage}
                src={coverPicture.id}
                layout="fill"
                loading="lazy"
                loader={imageLoaderForPicture(coverPicture, {
                    aspectRatio: RATIO['3/4'],
                })}
            />

            <Typography
                as="a"
                href={itineraryListPageUrl}
                scale="secondary-xs-regular"
                className={style.destinationHighlightCardText}
                onClick={() => {
                    trackEvent((ampli, defaultProperties) => {
                        ampli.primaryNavItinerariesListClicked({
                            ...defaultProperties,
                            destination_id: destination.id,
                            destination_slug: destination.slug,
                            destination_type: destination.parentDestination ? 'region' : 'country',
                            block: 'primary_nav',
                        });
                    });
                }}
            >
                {formatMessage(
                    { id: 'global.cta.listItineraries' },
                    {
                        destinationPreposition: destination.preposition,
                        destinationName: destination.name,
                        itineraryCount: 0,
                    },
                )}
                <div className={style.arrowCircle}>
                    <IconArrowRight className={style.arrowIcon} />
                </div>
            </Typography>
        </div>
    );
}

function Separator() {
    return <hr className={style.separator} />;
}
