import { HTMLAttributes, useState } from 'react';
import { useIntl } from 'react-intl';

import { useTrackEventAmplitude } from '../../../../../../core/Tracking/Amplitude/Amplitude';
import { Button } from '../../../../../../design-system/components/form/button/Button';
import { IconArrowRight } from '../../../../../../design-system/components/icons/ArrowRight';
import { NavItemButton } from '../../../../../../design-system/components/nav/nav-item/NavItem';
import { NormalizedWhereToGoMenu } from '../../../../../../resources/whereToGoMenu/api/prismic/normalizers';
import { useUrlGenerator } from '../../../../../../shared/hooks/useUrlGenerator';
import { Container } from '../../../../Container/Container';
import { PrimaryNavThematicCategoryValue, PrimaryNavValue } from '../../../types';
import { MobileMenuList } from '../../MenuList/MobileMenuList';
import { MobileView } from '../MobileView';

import { HowLongView } from './HowLongView';
import style from './NewWhereToGoMobileView.module.css';
import { WhatView } from './WhatView';
import { WhenView } from './WhenView';
import { WhoView } from './WhoView';

export function NewWhereToGoMobileView({
    whereToGoMenu: whereToGoMenu,
    onNavValueChange,
}: {
    onNavValueChange: (value: PrimaryNavValue) => void;
    whereToGoMenu: NonNullable<NormalizedWhereToGoMenu>;
}) {
    const { formatMessage } = useIntl();
    const [currentThematicCategory, setCurrentThematicCategory] =
        useState<PrimaryNavThematicCategoryValue>(null);

    const whereToGoTitle = currentThematicCategory
        ? null
        : formatMessage({
              id: 'global.header.globalNav.new.whereToGo.label',
          });

    return (
        <MobileView title={whereToGoTitle}>
            <Container>
                {currentThematicCategory === null ? (
                    <WhereToGoNav
                        whereToGoMenu={whereToGoMenu}
                        onThematicCategoryChange={(thematic) => {
                            setCurrentThematicCategory(thematic);
                        }}
                    />
                ) : null}

                {whereToGoMenu.who && currentThematicCategory === 'who' ? (
                    <WhoView
                        who={whereToGoMenu.who}
                        onNavValueChange={onNavValueChange}
                        setCurrentThematicCategory={setCurrentThematicCategory}
                    />
                ) : null}

                {whereToGoMenu.what && currentThematicCategory === 'what' ? (
                    <WhatView
                        what={whereToGoMenu.what}
                        onNavValueChange={onNavValueChange}
                        setCurrentThematicCategory={setCurrentThematicCategory}
                    />
                ) : null}

                {whereToGoMenu.when && currentThematicCategory === 'when' ? (
                    <WhenView
                        when={whereToGoMenu.when}
                        onNavValueChange={onNavValueChange}
                        setCurrentThematicCategory={setCurrentThematicCategory}
                    />
                ) : null}

                {whereToGoMenu.howLong && currentThematicCategory === 'howLong' ? (
                    <HowLongView
                        howLong={whereToGoMenu.howLong}
                        onNavValueChange={onNavValueChange}
                        setCurrentThematicCategory={setCurrentThematicCategory}
                    />
                ) : null}
            </Container>
        </MobileView>
    );
}

type WhereToGoNavProps = HTMLAttributes<HTMLElement> & {
    whereToGoMenu: NonNullable<NormalizedWhereToGoMenu>;
    onThematicCategoryChange: (thematic: PrimaryNavThematicCategoryValue) => void;
};

function WhereToGoNav({ whereToGoMenu, onThematicCategoryChange, ...props }: WhereToGoNavProps) {
    const { formatMessage } = useIntl();
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();

    return (
        <>
            <MobileMenuList {...props}>
                {whereToGoMenu.who ? (
                    <NavItemButton
                        iconTrailing={<IconArrowRight />}
                        onClick={() => {
                            onThematicCategoryChange('who');
                            trackEvent((ampli, defaultProperties) => {
                                ampli.primaryNavThematicSelected({
                                    ...defaultProperties,
                                    block: 'primary_nav',
                                    row_number: 1,
                                    thematic_group: 'who',
                                });
                            });
                        }}
                    >
                        {whereToGoMenu.who.title}
                    </NavItemButton>
                ) : null}

                {whereToGoMenu.when ? (
                    <NavItemButton
                        iconTrailing={<IconArrowRight />}
                        onClick={() => {
                            onThematicCategoryChange('when');
                            trackEvent((ampli, defaultProperties) => {
                                ampli.primaryNavThematicSelected({
                                    ...defaultProperties,
                                    block: 'primary_nav',
                                    row_number: 2,
                                    thematic_group: 'when',
                                });
                            });
                        }}
                    >
                        {whereToGoMenu.when.title}
                    </NavItemButton>
                ) : null}

                {whereToGoMenu.what ? (
                    <NavItemButton
                        iconTrailing={<IconArrowRight />}
                        onClick={() => {
                            onThematicCategoryChange('what');
                            trackEvent((ampli, defaultProperties) => {
                                ampli.primaryNavThematicSelected({
                                    ...defaultProperties,
                                    block: 'primary_nav',
                                    row_number: 3,
                                    thematic_group: 'what',
                                });
                            });
                        }}
                    >
                        {whereToGoMenu.what.title}
                    </NavItemButton>
                ) : null}

                {whereToGoMenu.howLong ? (
                    <NavItemButton
                        iconTrailing={<IconArrowRight />}
                        onClick={() => {
                            onThematicCategoryChange('howLong');
                            trackEvent((ampli, defaultProperties) => {
                                ampli.primaryNavThematicSelected({
                                    ...defaultProperties,
                                    block: 'primary_nav',
                                    row_number: 4,
                                    thematic_group: 'how_long',
                                });
                            });
                        }}
                    >
                        {whereToGoMenu.howLong.title}
                    </NavItemButton>
                ) : null}
            </MobileMenuList>

            <div className={style.footer}>
                <Button
                    priority="secondary"
                    reversed
                    href={generatePath('where_to_go')}
                    onClick={() => {
                        trackEvent((ampli, defaultProperties) => {
                            ampli.primaryNavWheretogoClicked({
                                ...defaultProperties,
                                block: 'primary_nav',
                            });
                        });
                    }}
                >
                    {formatMessage({
                        id: 'global.header.globalNav.new.whereToGo.cta',
                    })}
                </Button>
            </div>
        </>
    );
}
