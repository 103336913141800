import cx from 'classnames';
import { ComponentProps } from 'react';

import { Typography } from '../../typography/Typography';

import style from './NavMenuButton.module.css';

type NavMenuButtonProps = ComponentProps<'a'>;

export const NavMenuButton = ({ className, children, ...props }: NavMenuButtonProps) => {
    return (
        <Typography
            {...props}
            className={cx(className, style.navMenuButton)}
            as="a"
            scale="primary-xs-regular"
        >
            {children}
        </Typography>
    );
};
