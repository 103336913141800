import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useIntl } from 'react-intl';

import { useTrackEventAmplitude } from '../../../../../core/Tracking/Amplitude/Amplitude';
import { IconChevronDown } from '../../../../../design-system/components/icons/ChevronDown';
import { MenuItem } from '../../../../../design-system/components/nav/menu-item/MenuItem';
import { NavBarItem } from '../../../../../design-system/components/nav/nav-bar-item/NavBarItem';

import { usePrimaryNavCommitmentsLinks } from '../../Shared/Commitments/usePrimaryNavCommitmentsLinks';

import styles from './CommitmentsDropdown.module.css';

interface Props {
    className?: string;
    isOpen: boolean;
    onMouseEnter: () => void;
    onMouseEnterContent: () => void;
    onMouseLeave: () => void;
    onMouseLeaveContent: () => void;
    onOpenChange: (isOpen: boolean) => void;
}

export function CommitmentsDropdown({
    className,
    isOpen,
    onMouseEnter,
    onMouseEnterContent,
    onMouseLeave,
    onMouseLeaveContent,
    onOpenChange,
}: Props) {
    const { formatMessage } = useIntl();
    const { trackEvent } = useTrackEventAmplitude();

    const commitmentsLinks = usePrimaryNavCommitmentsLinks();

    return (
        <DropdownMenu.Root modal={false} open={isOpen}>
            <DropdownMenu.Trigger
                asChild
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onClick={() => {
                    onOpenChange(!isOpen);

                    trackEvent((ampli, defaultProperties) => {
                        ampli.primaryNavCommitmentsClicked({
                            ...defaultProperties,
                            block: 'primary_nav',
                        });
                    });
                }}
            >
                <NavBarItem
                    iconTrailing={<IconChevronDown />}
                    className={className}
                    isMenuOpen={isOpen}
                    as="button"
                >
                    {formatMessage({
                        id: 'global.header.globalNav.commitments.label',
                    })}
                </NavBarItem>
            </DropdownMenu.Trigger>

            <DropdownMenu.Content
                side="bottom"
                align="end"
                sideOffset={18}
                className={styles.menu}
                onMouseEnter={onMouseEnterContent}
                onMouseLeave={onMouseLeaveContent}
            >
                {commitmentsLinks.map((link) => {
                    return (
                        <DropdownMenu.Item key={link.name} asChild>
                            <MenuItem
                                className={styles.menuListItem}
                                href={link.url}
                                target="_blank"
                                rel="noreferrer"
                                onClick={() => {
                                    link.trackClick();
                                }}
                            >
                                {link.label}
                            </MenuItem>
                        </DropdownMenu.Item>
                    );
                })}
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
}
