import cx from 'classnames';

import { IconArrowLeft } from '../../../../../../design-system/components/icons/ArrowLeft';

import { NavItemButton } from '../../../../../../design-system/components/nav/nav-item/NavItem';

import style from './MobileSubNav.module.css';

type Props = {
    title: string;
    onPrevious: () => void;
    className?: string;
    sticky?: boolean;
    showPreviousButton: boolean;
};

export function MobileSubNav({ title, onPrevious, className, sticky, showPreviousButton }: Props) {
    return (
        <div
            className={cx(style.mobileSubNav, className, {
                [style.sticky]: sticky,
            })}
        >
            {showPreviousButton ? (
                <NavItemButton iconOnly iconLeading={<IconArrowLeft />} onClick={onPrevious} />
            ) : null}
            <div className={style.titleWrapper}>
                <div className={cx('font-body-bold', style.title)}>{title}</div>
            </div>
        </div>
    );
}
