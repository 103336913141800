import { useIntl } from 'react-intl';

import { NavItemLink } from '../../../../../../design-system/components/nav/nav-item/NavItem';
import { usePrimaryNavCommitmentsLinks } from '../../../Shared/Commitments/usePrimaryNavCommitmentsLinks';
import { MobileMenuList } from '../../MenuList/MobileMenuList';
import { MobileView } from '../MobileView';

export function CommitmentsMobileView() {
    const { formatMessage } = useIntl();
    const commitmentsLinks = usePrimaryNavCommitmentsLinks();

    return (
        <MobileView title={formatMessage({ id: 'global.header.globalNav.commitments.label' })}>
            <MobileMenuList>
                {commitmentsLinks.map((link) => {
                    return (
                        <NavItemLink
                            key={link.name}
                            href={link.url}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                link.trackClick();
                            }}
                        >
                            {link.label}
                        </NavItemLink>
                    );
                })}
            </MobileMenuList>
        </MobileView>
    );
}
