import { useId, useState } from 'react';
import { useIntl } from 'react-intl';

import { useTrackEventAmplitude } from '../../../../../core/Tracking/Amplitude/Amplitude';
import { IconChevronDown } from '../../../../../design-system/components/icons/ChevronDown';
import { IconChevronUp } from '../../../../../design-system/components/icons/ChevronUp';
import {
    NavItemButton,
    NavItemLink,
} from '../../../../../design-system/components/nav/nav-item/NavItem';
import { useUrlGenerator } from '../../../../../shared/hooks/useUrlGenerator';
import { useCurrentSite } from '../../../../../shared/providers/currentSite/useCurrentSite';
import { usePrimaryNavCommitmentsLinks } from '../../Shared/Commitments/usePrimaryNavCommitmentsLinks';
import { MobileMenuList } from '../MenuList/MobileMenuList';

import style from './MobileMenuBottomLinks.module.css';

export function MobileMenuBottomLinks() {
    return (
        <MobileMenuList>
            <Commitments />
            <Magazine />
            <HelpCenter />
        </MobileMenuList>
    );
}

function HelpCenter() {
    const { formatMessage } = useIntl();
    const { trackEvent } = useTrackEventAmplitude();
    const { currentSite } = useCurrentSite();

    if (!currentSite.pages.faq.url) {
        return null;
    }

    return (
        <NavItemLink
            href={currentSite.pages.faq.url}
            onClick={() => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.primaryNavHelpCenterClicked({
                        ...defaultProperties,
                        block: 'primary_nav',
                    });
                });
            }}
        >
            {formatMessage({
                id: 'global.header.globalNav.helpCenter.label',
            })}
        </NavItemLink>
    );
}

function Magazine() {
    const { formatMessage } = useIntl();
    const { trackEvent } = useTrackEventAmplitude();
    const { generatePath } = useUrlGenerator();

    return (
        <NavItemLink
            href={generatePath('magazine')}
            onClick={() => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.primaryNavMagazineClicked({
                        ...defaultProperties,
                        block: 'primary_nav',
                    });
                });
            }}
        >
            {formatMessage({
                id: 'global.header.globalNav.magazine.label',
            })}
        </NavItemLink>
    );
}

function Commitments() {
    const { formatMessage } = useIntl();
    const labelId = useId();
    const links = usePrimaryNavCommitmentsLinks();
    const { trackEvent } = useTrackEventAmplitude();
    const [isOpen, setIsOpen] = useState(false);

    function handleClickButton() {
        setIsOpen((isOpen) => {
            return !isOpen;
        });
        trackEvent((ampli, defaultProperties) => {
            ampli.primaryNavCommitmentsClicked({
                ...defaultProperties,
                block: 'primary_nav',
            });
        });
    }

    return (
        <>
            <NavItemButton
                id={labelId}
                iconTrailing={isOpen ? <IconChevronUp /> : <IconChevronDown />}
                onClick={handleClickButton}
            >
                {formatMessage({
                    id: 'global.header.globalNav.commitments.label',
                })}
            </NavItemButton>

            {isOpen ? (
                <ul aria-labelledby={labelId} className={style.commitmentsLinksList}>
                    {links.map((link) => {
                        return (
                            <li key={link.name}>
                                <NavItemLink
                                    href={link.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={() => {
                                        link.trackClick();
                                    }}
                                >
                                    {link.label}
                                </NavItemLink>
                            </li>
                        );
                    })}
                </ul>
            ) : null}
        </>
    );
}
