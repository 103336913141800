import cx from 'classnames';
import { Dispatch, forwardRef, MouseEvent, SetStateAction, useRef } from 'react';
import { useIntl } from 'react-intl';

import { useTrackEventAmplitude } from '../../../core/Tracking/Amplitude/Amplitude';
import { IconAccountCircle } from '../../../design-system/components/icons/AccountCircle';
import { IconBurger } from '../../../design-system/components/icons/Burger';
import { IconChevronDown } from '../../../design-system/components/icons/ChevronDown';
import { IconGlobe } from '../../../design-system/components/icons/Globe';
import { IconSearch } from '../../../design-system/components/icons/Search';
import { EvaneosLogo } from '../../../design-system/components/illustration/EvaneosLogo';
import { useModal } from '../../../design-system/components/modal/useModal';
import { NavBarItem } from '../../../design-system/components/nav/nav-bar-item/NavBarItem';
import { DestinationsMenu } from '../../../resources/destinationsMenu/domain/types/destinationsMenu';
import { HubDestinationMenu } from '../../../resources/hubDestinationMenu/domain/types/hubDestinationMenu';
import { useUrlGenerator } from '../../../shared/hooks/useUrlGenerator';
import { useCurrentSite } from '../../../shared/providers/currentSite/useCurrentSite';
import { useUser } from '../../../shared/providers/user/useUser';
import { LoginModal } from '../../Login/LoginModal';
import { useBreakPoint } from '../BreakPoint/BreakPoint';

import { CommitmentsDropdown } from './Desktop/CommimentsDropdown/CommitmentsDropdown';
import { DestinationsDropdownContent } from './Desktop/Destinations/DestinationsDropdownContent';
import { HubDestinationDropdownContent } from './Desktop/HubDestination/HubDestinationDropdownContent';
import { WhereToGoDropdownContent } from './Desktop/WhereToGo/WhereToGoDropdownContent';
import { MobileMenu } from './Mobile/Menu/MobileMenu';
import style from './ResponsiveMenu.module.css';
import { UserAccountDropdown } from './Shared/UserAccountDropdown/UserAccountDropdown';
import { PrimaryNavContextContinent, PrimaryNavValue, PrimaryNavWhereToGoMenu } from './types';

const HUB_DESTINATION_DROPDOWN_TRIGGER_ID = 'PrimaryNav-HubDestination-Dropdown-Trigger';
const HUB_DESTINATION_DROPDOWN_CONTENT_ID = 'PrimaryNav-HubDestination-Dropdown-Content';

const DESTINATIONS_DROPDOWN_TRIGGER_ID = 'PrimaryNav-Destinations-Dropdown-Trigger';
const DESTINATIONS_DROPDOWN_CONTENT_ID = 'PrimaryNav-Destinations-Dropdown-Content';

const WHERE_TO_GO_DROPDOWN_TRIGGER_ID = 'PrimaryNav-WhereToGo-Dropdown-Trigger';
const WHERE_TO_GO_DROPDOWN_CONTENT_ID = 'PrimaryNav-WhereToGo-Dropdown-Content';

type Props = {
    previewMode?: boolean;
    value: PrimaryNavValue;
    setValue: Dispatch<SetStateAction<PrimaryNavValue>>;
} & (
    | {
          noMenu: true;
          contextContinent?: never;
          hubDestinationMenu?: never;
          destinationsMenu?: never;
          whereToGoMenu?: never;
      }
    | {
          noMenu?: never;
          previewMode?: boolean;
          contextContinent: PrimaryNavContextContinent | null;
          hubDestinationMenu: HubDestinationMenu | null;
          destinationsMenu: DestinationsMenu | null;
          whereToGoMenu: PrimaryNavWhereToGoMenu | null;
      }
);

export function ResponsiveMenu({
    contextContinent,
    hubDestinationMenu,
    destinationsMenu,
    whereToGoMenu,
    noMenu,
    value,
    setValue,
    previewMode,
}: Props) {
    const hubDestinationTriggerRef = useRef(null);
    const destinationsTriggerRef = useRef(null);
    const whereToGoTriggerRef = useRef(null);
    const { isLowerThanXL } = useBreakPoint();

    // If destinationsMenu, destinations item is a dropdown trigger, else a simple link.
    const isDestinationsDropdown = destinationsMenu !== null;
    // If whereToGoMenu, whereToGo item is a dropdown trigger, else a simple link.
    const isWhereToGoDropdown = whereToGoMenu !== null;

    const isHubDestinationDropdownOpen = value === 'HUB_DESTINATION';
    const isDestinationsDropdownOpen = value === 'DESTINATIONS';
    const isWhereToGoDropdownOpen = value === 'WHERE_TO_GO';
    const isUserDropdownOpen = value === 'USER_ACCOUNT';
    const isCommitmentsDropdownOpen = value === 'COMMITMENTS_DESKTOP';

    let dropdownTimeout: NodeJS.Timeout;

    function handleClickOutsideDropdown() {
        if (window.innerWidth >= 1200) {
            setValue(null);
        }
    }

    function clearDropdownTimeout() {
        clearTimeout(dropdownTimeout);
    }

    function setDropdownTimeout(callback: () => void) {
        dropdownTimeout = setTimeout(callback, 500);
    }

    if (noMenu) {
        return (
            <nav className={style.navNoMenu} role="navigation">
                <EvaneosLogoItem previewMode={previewMode} />
            </nav>
        );
    }

    return (
        <>
            <nav className={style.nav} role="navigation">
                <div className={style.leftBlock}>
                    {hubDestinationMenu ? (
                        <HubDestinationButton
                            ref={hubDestinationTriggerRef}
                            destination={hubDestinationMenu.destination}
                            hasRegions={
                                hubDestinationMenu.regions.length > 0 ||
                                hubDestinationMenu.destinationsTypeRegion.length > 0
                            }
                            isOpen={isHubDestinationDropdownOpen}
                            onMouseEnter={() => {
                                if (isLowerThanXL()) {
                                    return;
                                }
                                clearDropdownTimeout();
                                setValue('HUB_DESTINATION');
                            }}
                            onMouseLeave={() => {
                                if (isLowerThanXL()) {
                                    return;
                                }
                                setDropdownTimeout(() => {
                                    setValue(null);
                                });
                            }}
                            onOpenChange={(isOpen) => {
                                setValue(isOpen ? 'HUB_DESTINATION' : null);
                            }}
                        />
                    ) : null}

                    <DestinationsItem
                        isOpen={isDestinationsDropdownOpen}
                        isDropdown={isDestinationsDropdown}
                        onMouseEnter={() => {
                            if (!isDestinationsDropdown || isLowerThanXL()) {
                                return;
                            }
                            clearDropdownTimeout();
                            setValue('DESTINATIONS');
                        }}
                        onMouseLeave={() => {
                            if (!isDestinationsDropdown || isLowerThanXL()) {
                                return;
                            }
                            setDropdownTimeout(() => {
                                setValue(null);
                            });
                        }}
                        onOpenChange={(isOpen) => {
                            setValue(isOpen ? 'DESTINATIONS' : null);
                        }}
                        ref={destinationsTriggerRef}
                    />

                    <WhereToGoItem
                        isOpen={isWhereToGoDropdownOpen}
                        isDropdown={isWhereToGoDropdown}
                        onMouseEnter={() => {
                            if (!isWhereToGoDropdown || isLowerThanXL()) {
                                return;
                            }
                            clearDropdownTimeout();
                            setValue('WHERE_TO_GO');
                        }}
                        onMouseLeave={() => {
                            if (!isWhereToGoDropdown || isLowerThanXL()) {
                                return;
                            }
                            setDropdownTimeout(() => {
                                setValue(null);
                            });
                        }}
                        onOpenChange={(isOpen) => {
                            setValue(isOpen ? 'WHERE_TO_GO' : null);
                        }}
                        ref={whereToGoTriggerRef}
                    />
                </div>

                <div className={style.logoBlock}>
                    <EvaneosLogoItem previewMode={previewMode} />
                </div>

                <div className={style.rightBlock}>
                    <CommitmentsItem
                        isOpen={isCommitmentsDropdownOpen}
                        onMouseEnter={() => {
                            clearDropdownTimeout();
                            setValue('COMMITMENTS_DESKTOP');
                        }}
                        onMouseLeave={() => {
                            setDropdownTimeout(() => {
                                setValue(null);
                            });
                        }}
                        onMouseEnterContent={() => {
                            clearDropdownTimeout();
                            setValue('COMMITMENTS_DESKTOP');
                        }}
                        onMouseLeaveContent={() => {
                            setDropdownTimeout(() => {
                                setValue(null);
                            });
                        }}
                        onOpenChange={(isOpen) => {
                            setValue(isOpen ? 'COMMITMENTS_DESKTOP' : null);
                        }}
                    />

                    <HelpCenterItem />

                    <UserAccountItem
                        isOpen={isUserDropdownOpen}
                        onMouseEnter={() => {
                            if (isLowerThanXL()) {
                                return;
                            }
                            clearDropdownTimeout();
                            setValue('USER_ACCOUNT');
                        }}
                        onMouseLeave={() => {
                            if (isLowerThanXL()) {
                                return;
                            }
                            setDropdownTimeout(() => {
                                setValue(null);
                            });
                        }}
                        onOpenChange={(isOpen) => {
                            setValue(isOpen ? 'USER_ACCOUNT' : null);
                        }}
                    />
                </div>
            </nav>

            <MobileMenu
                navValue={value}
                contextContinent={contextContinent}
                hubDestinationDropdown={hubDestinationMenu}
                destinationsMenu={destinationsMenu}
                whereToGoMenu={whereToGoMenu}
                onNavValueChange={(value) => {
                    setValue(value);
                }}
            />

            <div className={cx(style.dropdownContainer, style.hiddenMobile)}>
                {hubDestinationMenu ? (
                    <HubDestinationDropdownContent
                        triggerRef={hubDestinationTriggerRef}
                        aria-labelledby={HUB_DESTINATION_DROPDOWN_TRIGGER_ID}
                        hubDestinationMenu={hubDestinationMenu}
                        id={HUB_DESTINATION_DROPDOWN_CONTENT_ID}
                        hidden={!isHubDestinationDropdownOpen}
                        onClickOutside={handleClickOutsideDropdown}
                        onMouseEnter={() => {
                            clearDropdownTimeout();
                            setValue('HUB_DESTINATION');
                        }}
                        onMouseLeave={() => {
                            setDropdownTimeout(() => {
                                setValue(null);
                            });
                        }}
                    />
                ) : null}

                {destinationsMenu ? (
                    <DestinationsDropdownContent
                        triggerRef={destinationsTriggerRef}
                        aria-labelledby={DESTINATIONS_DROPDOWN_TRIGGER_ID}
                        contextContinent={contextContinent}
                        destinationsMenu={destinationsMenu}
                        id={DESTINATIONS_DROPDOWN_CONTENT_ID}
                        hidden={!isDestinationsDropdownOpen}
                        onClickOutside={handleClickOutsideDropdown}
                        onMouseEnter={() => {
                            clearDropdownTimeout();
                            setValue('DESTINATIONS');
                        }}
                        onMouseLeave={() => {
                            setDropdownTimeout(() => {
                                setValue(null);
                            });
                        }}
                    />
                ) : null}

                {whereToGoMenu ? (
                    <WhereToGoDropdownContent
                        whereToGoMenu={whereToGoMenu}
                        onClickOutside={handleClickOutsideDropdown}
                        triggerRef={whereToGoTriggerRef}
                        hidden={!isWhereToGoDropdownOpen}
                        aria-labelledby={WHERE_TO_GO_DROPDOWN_TRIGGER_ID}
                        id={WHERE_TO_GO_DROPDOWN_CONTENT_ID}
                        onMouseEnter={() => {
                            clearDropdownTimeout();
                            setValue('WHERE_TO_GO');
                        }}
                        onMouseLeave={() => {
                            setDropdownTimeout(() => {
                                setValue(null);
                            });
                        }}
                    />
                ) : null}
            </div>
        </>
    );
}

function EvaneosLogoItem({ previewMode }: { previewMode?: boolean }) {
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();

    return (
        <a
            className={style.logoLink}
            href={generatePath('home')}
            onClick={() => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.primaryNavLogoClicked({
                        ...defaultProperties,
                        block: 'primary_nav',
                    });
                });
            }}
        >
            <EvaneosLogo className={style.logo} />
            {previewMode ? (
                <span className={cx('font-l-extrabold', style.previewModeText)}>Preview</span>
            ) : null}
        </a>
    );
}

const HubDestinationButton = forwardRef<
    HTMLButtonElement,
    {
        destination: HubDestinationMenu['destination'];
        hasRegions: boolean;
        isOpen: boolean;
        onMouseEnter: () => void;
        onMouseLeave: () => void;
        onOpenChange: (isOpen: boolean) => void;
    }
>(({ destination, hasRegions, isOpen, onMouseEnter, onMouseLeave, onOpenChange }, ref) => {
    const { formatMessage } = useIntl();
    const { trackEvent } = useTrackEventAmplitude();

    const country = destination.parentDestination ?? destination;
    const destinationName = country.name;
    const label = hasRegions
        ? formatMessage(
              { id: 'global.header.globalNav.hubDestination.labelWithRegions' },
              { destinationName },
          )
        : destinationName;

    return (
        <NavBarItem
            as="button"
            ref={ref}
            iconLeading={<IconBurger />}
            iconTrailing={<IconChevronDown />}
            onClick={() => {
                onOpenChange(!isOpen);
                trackEvent((ampli, defaultProperties) => {
                    ampli.primaryNavHubDestinationClicked({
                        ...defaultProperties,
                        block: 'primary_nav',
                        destination_id: country.id,
                        destination_slug: country.slug,
                    });
                });
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            isMenuOpen={isOpen}
        >
            {label}
        </NavBarItem>
    );
});

const DestinationsItem = forwardRef<
    HTMLAnchorElement,
    {
        isOpen: boolean;
        isDropdown: boolean;
        onMouseEnter: () => void;
        onMouseLeave: () => void;
        onOpenChange: (isOpen: boolean) => void;
    }
>(({ isOpen, isDropdown, onMouseEnter, onMouseLeave, onOpenChange }, ref) => {
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();
    const { formatMessage } = useIntl();

    return (
        <>
            <NavBarItem
                as="a"
                ref={ref}
                iconLeading={<IconGlobe />}
                iconTrailing={isDropdown ? <IconChevronDown /> : undefined}
                aria-controls={isDropdown ? DESTINATIONS_DROPDOWN_CONTENT_ID : undefined}
                aria-expanded={isDropdown ? isOpen : undefined}
                href={generatePath('destination_list')}
                id={DESTINATIONS_DROPDOWN_TRIGGER_ID}
                onClick={(event: MouseEvent) => {
                    if (isDropdown) {
                        event.preventDefault();
                        onOpenChange(!isOpen);
                    }

                    trackEvent((ampli, defaultProperties) => {
                        ampli.primaryNavAllDestinationsClicked({
                            ...defaultProperties,
                            block: 'primary_nav',
                        });
                    });
                }}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                isMenuOpen={isOpen}
            >
                {formatMessage({
                    id: 'global.header.globalNav.destinations.label',
                })}
            </NavBarItem>
        </>
    );
});

const WhereToGoItem = forwardRef<
    HTMLAnchorElement,
    {
        isOpen: boolean;
        isDropdown: boolean;
        onMouseEnter: () => void;
        onMouseLeave: () => void;
        onOpenChange: (isOpen: boolean) => void;
    }
>(({ isOpen, isDropdown, onMouseEnter, onMouseLeave, onOpenChange }, ref) => {
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();
    const { formatMessage } = useIntl();

    return (
        <NavBarItem
            as="a"
            ref={ref}
            iconLeading={<IconSearch />}
            iconTrailing={isDropdown ? <IconChevronDown /> : undefined}
            aria-controls={isDropdown ? WHERE_TO_GO_DROPDOWN_CONTENT_ID : undefined}
            aria-expanded={isDropdown ? isOpen : undefined}
            href={generatePath('where_to_go')}
            id={WHERE_TO_GO_DROPDOWN_TRIGGER_ID}
            onClick={(event: MouseEvent) => {
                if (isDropdown) {
                    event.preventDefault();
                    onOpenChange(!isOpen);
                }

                trackEvent((ampli, defaultProperties) => {
                    ampli.primaryNavExploreClicked({
                        ...defaultProperties,
                        block: 'primary_nav',
                    });
                });
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            isMenuOpen={isOpen}
        >
            {formatMessage({
                id: 'global.header.globalNav.new.whereToGo.label',
            })}
        </NavBarItem>
    );
});

function CommitmentsItem({
    isOpen,
    onMouseEnter,
    onMouseEnterContent,
    onMouseLeave,
    onMouseLeaveContent,
    onOpenChange,
}: {
    isOpen: boolean;
    onMouseEnter: () => void;
    onMouseEnterContent: () => void;
    onMouseLeave: () => void;
    onMouseLeaveContent: () => void;
    onOpenChange: (isOpen: boolean) => void;
}) {
    return (
        <CommitmentsDropdown
            isOpen={isOpen}
            className={style.hiddenMobile}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseEnterContent={onMouseEnterContent}
            onMouseLeaveContent={onMouseLeaveContent}
            onOpenChange={onOpenChange}
        />
    );
}

function HelpCenterItem() {
    const { currentSite } = useCurrentSite();
    const { formatMessage } = useIntl();
    const { trackEvent } = useTrackEventAmplitude();

    if (!currentSite.pages.faq.url) {
        return null;
    }

    return (
        <NavBarItem
            as="a"
            className={style.hiddenMobile}
            href={currentSite.pages.faq.url}
            onClick={() => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.primaryNavHelpCenterClicked({
                        ...defaultProperties,
                        block: 'primary_nav',
                    });
                });
            }}
            isMenuOpen={false}
        >
            {formatMessage({
                id: 'global.header.globalNav.helpCenter.shortLabel',
            })}
        </NavBarItem>
    );
}

function UserAccountItem({
    isOpen,
    onOpenChange,
    onMouseEnter,
    onMouseLeave,
}: {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}) {
    const { user } = useUser();
    const loginModal = useModal();
    const { formatMessage } = useIntl();
    const { trackEvent } = useTrackEventAmplitude();

    if (user) {
        return (
            <UserAccountDropdown
                user={user}
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            />
        );
    }

    return (
        <>
            <NavBarItem
                displayDesktopIconLeading={true}
                iconLeading={<IconAccountCircle />}
                onClick={() => {
                    loginModal.open();
                    trackEvent((ampli, defaultProperties) => {
                        ampli.primaryNavAccountClicked({
                            ...defaultProperties,
                            block: 'primary_nav',
                        });
                    });
                }}
                isMenuOpen={isOpen}
                as="button"
            >
                {formatMessage({
                    id: 'global.header.globalNav.account.label',
                })}
            </NavBarItem>
            {loginModal.isOpen ? <LoginModal context="default" {...loginModal} /> : null}
        </>
    );
}
