import cx from 'classnames';
import { ComponentProps } from 'react';

import { Typography } from '../../typography/Typography';

import style from './NavMenuLink.module.css';

type NavMenuLinkProps = ComponentProps<'a'>;

export function NavMenuLink({ className, children, ...props }: NavMenuLinkProps) {
    return (
        <Typography
            {...props}
            as="a"
            className={cx(className, style.navMenuLink)}
            scale="primary-xs-regular"
        >
            {children}
        </Typography>
    );
}
