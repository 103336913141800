import { Typography } from '../../../../../../design-system/components/typography/Typography';

import style from './MobileMenuTitle.module.css';

type Props = {
    title: string;
};

export function MobileMenuTitle({ title }: Props) {
    return (
        <Typography className={style.destinationName} scale="secondary-s-regular">
            {title}
        </Typography>
    );
}
