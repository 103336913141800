import React from 'react';

import { DestinationsMenu } from '../../../../../resources/destinationsMenu/domain/types/destinationsMenu';
import { HubDestinationMenu } from '../../../../../resources/hubDestinationMenu/domain/types/hubDestinationMenu';
import { NormalizedWhereToGoMenu } from '../../../../../resources/whereToGoMenu/api/prismic/normalizers';
import { PrimaryNavContextContinent, PrimaryNavValue } from '../../types';
import { CommitmentsMobileView } from '../View/Commitments/CommitmentsMobileView';
import { DestinationsMobileView } from '../View/Destinations/DestinationsMobileView';
import { HubDestinationMobileView } from '../View/HubDestination/HubDestinationMobileView';
import { NewWhereToGoMobileView } from '../View/NewWhereToGo/NewWhereToGoMobileView';

import styles from './MobileMenu.module.css';

type Props = {
    navValue: PrimaryNavValue;
    contextContinent: PrimaryNavContextContinent | null;
    hubDestinationDropdown: HubDestinationMenu | null;
    destinationsMenu: DestinationsMenu | null;
    whereToGoMenu: NormalizedWhereToGoMenu | null;
    onNavValueChange: (view: PrimaryNavValue) => void;
};

export function MobileMenu({
    navValue,
    contextContinent,
    hubDestinationDropdown,
    destinationsMenu,
    whereToGoMenu,
    onNavValueChange,
}: Props) {
    if (navValue === null || navValue === 'USER_ACCOUNT') {
        return null;
    }

    return (
        <div className={styles.container}>
            {navValue === 'HUB_DESTINATION' && hubDestinationDropdown ? (
                <HubDestinationMobileView hubDestinationMenu={hubDestinationDropdown} />
            ) : null}

            {navValue === 'DESTINATIONS' && destinationsMenu ? (
                <DestinationsMobileView
                    contextContinent={contextContinent}
                    destinationsMenu={destinationsMenu}
                />
            ) : null}

            {navValue === 'WHERE_TO_GO' && whereToGoMenu ? (
                <NewWhereToGoMobileView
                    onNavValueChange={onNavValueChange}
                    whereToGoMenu={whereToGoMenu}
                />
            ) : null}

            {navValue === 'COMMITMENTS_MOBILE' ? <CommitmentsMobileView /> : null}
        </div>
    );
}
